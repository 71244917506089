// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {

	data() { return {
		assoc_framework_menu_items: {},
		assoc_type_menu_options: {},

		showing_crosswalk: false,
		crosswalk_right_identifier: null,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),

		all_associations_showing() {
			let all_showing = true
			for (let lsdoc_id in this.assoc_framework_menu_items) {
				if (!this.assoc_framework_menu_items[lsdoc_id].showing) all_showing = false
			}
			return all_showing
		},
		all_association_types_showing() {
			let all_showing = true
			for (let type in this.assoc_type_menu_options) {
				if (!this.assoc_type_menu_options[type].used) continue
				if (!this.assoc_type_menu_options[type].showing) all_showing = false
			}
			return all_showing
		},
		no_associations_showing() {
			let none_showing = true
			for (let lsdoc_id in this.assoc_framework_menu_items) {
				if (this.assoc_framework_menu_items[lsdoc_id].showing) none_showing = false
			}
			return none_showing
		},
		has_associated_frameworks() {
			// return true
			for (let id in this.assoc_framework_menu_items) {
				let afmi = this.assoc_framework_menu_items[id]
				// we don't count the copies item here; that's accounted for in has_copied_items
				if (id == 'copies') continue
				if (afmi.has_assocs || (afmi.associations && afmi.associations.length > 0)) return true
			}
			return false
		},
		has_copied_items() {
			// return true
			return (this.assoc_framework_menu_items.copies && this.assoc_framework_menu_items.copies.count > 0)
		},
		new_crosswalk_framework_options() {
			if (empty(this.framework_record)) return []

			let arr = []
			for (let fr of this.$store.getters.filtered_framework_records) {
				let doc = fr.json.CFDocument
				if (doc.identifier == this.framework_record.lsdoc_identifier) continue
				if (doc.frameworkType == "crosswalk") continue
				arr.push({value: doc.identifier, text: doc.title})
			}
			// sort by title
			arr.sort((a,b)=>U.natural_sort(a.text, b.text))
			return arr
		},
	},
	watch: {
		assoc_framework_menu_items: { deep:true, handler() { this.update_associations_to_show() }},
	},
	methods: {
		show_associations_btn_clicked() {
			// when the user clicks the button to show association options, if there are any crosswalks we haven't yet loaded, load them now so we can show their counts
			let need_to_load = false
			for (let id in this.assoc_framework_menu_items) {
				if (this.assoc_framework_menu_items[id].load_now === false) {
					this.assoc_framework_menu_items[id].load_now = true
					need_to_load = true
				}
			}
			if (need_to_load) {
				this.update_associations_to_show()
			}
		},

		// call this to add/remove associations from the lists of associations that should be displayed, and/or ensure that an association's framework and associationType are showing
		update_association_display(params) {
			// console.warn('update_association_display')
			let { framework_id, assoc, associationType, actions } = params
			// 6/30/2024: for actions.add/remove, all we actually need to do is call update_associations_to_show, which we do below (update_associations_to_show will re-build the list of associations)

			this.update_frameworks_with_associations() // this will in turn call update_associations_to_show

			// if actions includes 'display', show the provided framework_id and associationType; note that we have to do this after calling update_frameworks_with_associations
			if (actions.includes('display')) {
				if (this.assoc_framework_menu_items[framework_id]) this.assoc_framework_menu_items[framework_id].showing = true
				if (assoc && this.assoc_type_menu_options[assoc.associationType]) this.assoc_type_menu_options[assoc.associationType].showing = true
				if (associationType && this.assoc_type_menu_options[associationType]) this.assoc_type_menu_options[associationType].showing = true
			}
		},

		// this updates our records of which other frameworks have associations to the current framework that we could show
		update_frameworks_with_associations() {
			// get previously-set settings from lst hash (we will save these in update_associations_to_show)
			let previous_sfwa = this.$store.state.lst.assoc_framework_menu_items4[this.lsdoc_identifier] ?? {}

			// console.warn('update_frameworks_with_associations', previous_sfwa[this.lsdoc_identifier] !== false)

			this.assoc_framework_menu_items = {}
			// start with intra-framework assocs and copies (if count is 0, we won't show them)
			this.$set(this.assoc_framework_menu_items, this.lsdoc_identifier, {
				showing: (previous_sfwa[this.lsdoc_identifier] !== false),	// default to showing intra-framework associations
				// showing: previous_sfwa[this.lsdoc_identifier],
				title: 'This Framework (intra-framework associations)',
				has_assocs: false,
				associations: [],
				count: 0,
			})
			this.$set(this.assoc_framework_menu_items, 'copies', {
				showing: previous_sfwa['copies'],	// default to not showing copies
				title: 'Copied Items',
				has_assocs: false,
				associations: [],
				count: 0,
			})

			// find crosswalk frameworks and save their associations based on their associated framework
			for (let fr of this.framework_records) {
				if (fr.json.CFDocument.frameworkType == "crosswalk" && fr.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.includes(this.lsdoc_identifier)) {
					// skip crosswalk frameworks with no associations
					if (fr.framework_json_loaded && (empty(fr.json.CFAssociations) || fr.json.CFAssociations.length == 0)) continue

					let associated_framework_id = fr.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.find(x=>x!=this.lsdoc_identifier)

					// unless the framework we're viewing is a sandbox, don't show crosswalk frameworks involving another sandbox
					if (!this.is_sandbox) {
						let other_fr = this.framework_records.find(x=>x.lsdoc_identifier == associated_framework_id)
						if (other_fr?.ss_framework_data.sandboxOfIdentifier) {
							continue
						}
					}
										
					// add to assoc_framework_menu_items, or create a new entry
					if (!empty(this.assoc_framework_menu_items[associated_framework_id])) {
						this.assoc_framework_menu_items[associated_framework_id].crosswalk = fr
						this.assoc_framework_menu_items[associated_framework_id].load_now = false

					} else {
						this.$set(this.assoc_framework_menu_items, associated_framework_id, {
							showing: previous_sfwa[associated_framework_id],	// default to not showing assocs from crosswalk frameworks
							title: this.framework_records.find(x=>x.lsdoc_identifier==associated_framework_id)?.json.CFDocument.title ?? '???',
							has_assocs: false,
							associations: [],
							count: 0,
							// note that here we save the framework_record of the crosswalk
							crosswalk: fr,
							load_now: false,	// we set this to true if/when we need to load it
						})
					}
				}
			}

			let check_ids = (origin_framework_id, destination_framework_id, assoc) => {
				// only run once both have been fetched -- and either the origin or the destination might be returned from the server first first
				if (!origin_framework_id || !destination_framework_id) return 

				// don't add if the association does not reference this framework as either the origin or the destination...
				if (origin_framework_id != this.lsdoc_identifier && destination_framework_id != this.lsdoc_identifier) {
					// HOWEVER, it's OK if either the origin or destination item is in the home framework
					if (!this.cfo.cfitems[assoc.originNodeURI.identifier] && !this.cfo.cfitems[assoc.destinationNodeURI.identifier]) {
						return 
					}
				}

				// don't add document-document associations
				if (assoc.originNodeURI.identifier == origin_framework_id || assoc.destinationNodeURI.identifier == origin_framework_id || assoc.originNodeURI.identifier == destination_framework_id || assoc.destinationNodeURI.identifier == destination_framework_id) {
					return
				}
			
				// if this is a cross-framework assoc, add it to the record for the framework that isn't the framework we're showing
				if (destination_framework_id != this.lsdoc_identifier) {
					this.add_framework_association_for_framework_menu(destination_framework_id, assoc)
				} else {
					// if we're here, either origin_framework_id is the other framework, or both origin_framework_id and destination_framework_id are this framework
					this.add_framework_association_for_framework_menu(origin_framework_id, assoc)
				}
			}

			// first add "real" assocs from CFAssociations, plus assocs from derivative_original_json if we have it
			let assoc_arr = this.framework_record.json.CFAssociations
			if (this.cfo.derivative_CFAssociations) assoc_arr = assoc_arr.concat(this.cfo.derivative_CFAssociations)

			for (let assoc of assoc_arr) {
				// ignore isChildOfs
				if (assoc.associationType == "isChildOf") continue
				if (assoc.originNodeURI.identifier == this.lsdoc_identifier || assoc.destinationNodeURI.identifier == this.lsdoc_identifier) continue

				let origin_framework_id
				let destination_framework_id
				
				if (assoc.originNodeURI.title.search(/\s*\(:(\S+):\)\s*$/) > -1) {
					// if we found it, this will be the identifier for the framework this item comes from
					origin_framework_id = RegExp.$1
					check_ids(origin_framework_id, destination_framework_id, assoc)
				} else U.get_lsdoc_identifier_from_item_identifier(assoc.originNodeURI.identifier, assoc).then(identifier=>{
					origin_framework_id = identifier
					check_ids(origin_framework_id, destination_framework_id, assoc)
				}).catch(e=>{console.log('SERVICE FAILED (update_frameworks_with_associations - 1)', object_copy(assoc))})

				if (assoc.destinationNodeURI.title.search(/\s*\(:(\S+):\)\s*$/) > -1) {
					// if we found it, this will be the identifier for the framework this item comes from
					destination_framework_id = RegExp.$1
					// if (destination_framework_id == '97c883b4-8590-454f-b222-f28298ec9a81') debugger
					check_ids(origin_framework_id, destination_framework_id, assoc)
				} else U.get_lsdoc_identifier_from_item_identifier(assoc.destinationNodeURI.identifier, assoc).then(identifier=>{
					destination_framework_id = identifier
					check_ids(origin_framework_id, destination_framework_id, assoc)
				}).catch(e=>{console.log('SERVICE FAILED (update_frameworks_with_associations - 2)', object_copy(assoc))})
			}

			// add aliases, using alias_hash created in build_cfo. aliases are only allowed within frameworks (and are being phased out)
			for (let item_identifier in this.cfo.alias_hash) {
				let tree_node_arr = this.cfo.alias_hash[item_identifier]
				for (let i = 1; i < tree_node_arr.length; ++i) {
					// put the tree_keys in the title field; the first-created node in the tree is considered the "origin"
					// they must be strings for the associations to be rendered properly
					let a = {
						associationType: 'aliasOf',
						identifier: U.new_uuid(),	// needed in AssociationsTable
						originNodeURI: { identifier: item_identifier, title: tree_node_arr[0].tree_key+'' },
						destinationNodeURI: { identifier: item_identifier, title: tree_node_arr[i].tree_key+'' },
					}
					// console.log('alias', a)
					this.add_framework_association_for_framework_menu(this.lsdoc_identifier, a)
				}
			}

			// now add 'copiedFromSource' assocs to the 'copies' assoc_framework_menu_items field
			for (let item of this.framework_record.json.CFItems) {
				// this could be determined by sourceItemIdentifier (but not if sourceItemIdentifier==identifier!)
				if (item.extensions?.sourceItemIdentifier && item.extensions.sourceItemIdentifier != item.identifier) {
					let a = {
						associationType: 'copiedFromSource',
						originNodeURI: { identifier: item.identifier, title: '' },
						destinationNodeURI: { identifier: item.extensions.sourceItemIdentifier, title: '' },
					}					
					this.add_framework_association_for_framework_menu('copies', a)
				}
			}

			// now add 'aliasOfExternal' assocs to the framework(s) the aliases alias to
			for (let identifier in this.framework_record.cfo.between_framework_alias_hash) {
				let external_framework_identifier = this.framework_record.cfo.between_framework_alias_hash[identifier]

				let a = {
					associationType: 'aliasOfExternal',
					originNodeURI: { identifier: identifier, title: `alias (:${external_framework_identifier}:)` },
					destinationNodeURI: { identifier: identifier, title: `alias (:${external_framework_identifier}:)` },
				}					
				this.add_framework_association_for_framework_menu(external_framework_identifier, a)
			}
		},

		// this updates the framework's cfo.displayed_associations_hash, which determines which associations are showing in the interface
		update_associations_to_show() {
			if (!this.framework_record?.cfo) return
			
			// establish the debounce fn if necessary
			if (empty(this.fn_debounced)) {
				this.fn_debounced = U.debounce(()=>{
					// console.warn("update_associations_to_show")

					// first make sure all the selected crosswalk frameworks are fully loaded
					for (let lsdoc_id in this.assoc_framework_menu_items) {
						let option = this.assoc_framework_menu_items[lsdoc_id]
						// console.warn('checking ' + option.title)
						if (option.crosswalk && (option.showing || option.load_now)) {
							option.load_now = false
							if (option.crosswalk.framework_json_loading) {
								// already loading the crosswalk; we'll re-call update_associations_to_show when it finishes
								return
							} else if (!option.crosswalk.framework_json_loaded) {
								// try to load the crosswalk from the server
								if (!this.loading_start_for_crosswalk) U.loading_start(`Loading crosswalk(s)…`)
								this.loading_start_for_crosswalk = true
								this.$store.dispatch('get_lsdoc', option.crosswalk.lsdoc_identifier).finally(()=>{
									// console.warn('loaded crosswalk ' + option.title)
									// U.loading_stop()
									// we don't need to build a full cfo for the crosswalk, because it only has associations. But we do need to:
									// set framework_json_loading to false
									this.$store.commit('set', [option.crosswalk, 'framework_json_loading', false])

									// and process each of the CFAssociations
									for (let i = 0; i < option.crosswalk.json.CFAssociations.length; ++i) {
										option.crosswalk.json.CFAssociations[i] = new CFAssociation(option.crosswalk.json.CFAssociations[i])
									}

									// if the crosswalk doesn't actually have any associations (and there aren't any "normal" associations), kill it from the menu
									if (U.get_crosswalk_sme_associations(option.crosswalk.json.CFAssociations).length == 0 && option.associations.length == 0) {
										this.$delete(this.assoc_framework_menu_items, lsdoc_id)
									}

									// whether this worked or not, re-call update_associations_to_show
									this.update_associations_to_show()
								})
								return
							}
						}
					}
					// if we get to here, all crosswalks are loaded
					if (this.loading_start_for_crosswalk) U.loading_stop()
					this.loading_start_for_crosswalk = false

					// build list of types to show based on possibly-new crosswalk frameworks that have been added. start by getting previously-set settings from hash
					let previous_atmo = this.$store.state.lst.assoc_type_menu_options2[this.lsdoc_identifier] ?? {}
					let type_labels = this.$store.state.association_type_labels
					let options = {}
					for (let type in type_labels) {
						// skip isChildOf and copiedFromSource
						// if (type == "isChildOf" || type == 'copiedFromSource') continue

						let used = this.association_type_used(type)

						// if the user explicitly hid this type before, keep it hidden; but if it was showing before, or if this the first time we're calculating assoc_type_menu_options, have it showing
						// exception: don't show aliases by default
						let showing
						if (previous_atmo[type]?.showing === false) showing = false
						else if (previous_atmo[type]?.showing === true || type != 'aliasOf') showing = true
						else showing = false
						
						options[type] = {
							name: type_labels[type],
							used: used,
							showing: showing,
							count: 0,
						}
					}
					this.assoc_type_menu_options = options

					// now update the list of frameworks to show, which was started in update_frameworks_with_associations
					// start with empty hash, then go through each framework with associations
					let hash = {}
					for (let lsdoc_id in this.assoc_framework_menu_items) {
						let option = this.assoc_framework_menu_items[lsdoc_id]
						// TODO: if this array concat slows things down this could be moved to a fn or something...
						let associations = option.associations || []
						// the count starts with associations.length, which is the number of associations that are in the base framework
						// PW 8/21/2024: NO, we will count the associations in the base framework below.
						// let new_count = associations.length
						let new_count = 0
						if (option.crosswalk?.json.CFAssociations) associations = associations.concat(option.crosswalk.json.CFAssociations)
						for (let assoc of associations) {
							// don't count auto-associations
							if (assoc.associationType == 'ext:satchelAIOnly') continue

							if (!this.assoc_type_menu_options[assoc.associationType]) {
								console.warn('no this.assoc_type_menu_options for ' + assoc.associationType)
								continue
							}

							// if we get to here we have at least one showable assoc for this framework, so set has_assocs to true (we use this to determine whether or not to show the assocs menu)
							option.has_assocs = true
							
							// but only update counts if one of the conditions below are met
							// TODO: I think we can take this out, but leave it here for a while just in case
							if (true ||
								// (we're showing this framework's assocs, and (we're not showing the crosswalk editor or (we are showing the crosswalk editor but this.crosswalk_right_identifier is empty[?])))
								(option.showing && (!this.showing_crosswalk || !this.crosswalk_right_identifier)) || 
								// OR we're showing the crosswalk editor and this.crosswalk_right_identifier is this framework
								(this.showing_crosswalk && lsdoc_id == this.crosswalk_right_identifier)
							) {
								// update overall count, but not for aliasOfExternal assocs, which are already counted in associations.length
								if (assoc.associationType != 'aliasOfExternal') new_count += 1

								// ... but only update type count and show the selected association types for the showing frameworks. note that for copiedFromSource, we only look at assoc_framework_menu_items['copied'], not assoc_type_menu_options
								if (option.showing && (assoc.associationType == 'copiedFromSource' || this.assoc_type_menu_options[assoc.associationType].showing)) {
									// update type count
									if (assoc.associationType != 'copiedFromSource') this.assoc_type_menu_options[assoc.associationType].count += 1
									if (!hash[assoc.destinationNodeURI.identifier]) hash[assoc.destinationNodeURI.identifier] = []
									hash[assoc.destinationNodeURI.identifier].push(assoc)
									
									if (assoc.associationType != 'aliasOf' && assoc.associationType != 'aliasOfExternal') {
										// for an alias, originNodeURI.identifier and destinationNodeURI.identifier are the same, so we only add it once
										if (!hash[assoc.originNodeURI.identifier]) hash[assoc.originNodeURI.identifier] = []
										hash[assoc.originNodeURI.identifier].push(assoc)
									}
								}
							}
						}
						if (option.count != new_count) option.count = new_count
					}
					this.$store.commit('set', [this.framework_record.cfo, 'displayed_associations_hash', hash])

					// get counts for folders
					vapp.$store.commit('set', [this.cfo, 'displayed_association_counts_hash', U.get_association_counts_hash(this.cfo)])

					// store latest values of assoc_framework_menu_items and assoc_type_menu_options in lst; for the former, only store the "showing" value
					let afmi = {}
					for (let id in this.assoc_framework_menu_items) afmi[id] = this.assoc_framework_menu_items[id].showing
					this.$store.commit('lst_set_hash', ['assoc_framework_menu_items4', this.lsdoc_identifier, afmi])
					this.$store.commit('lst_set_hash', ['assoc_type_menu_options2', this.lsdoc_identifier, this.assoc_type_menu_options])
				}, 100)
			}
			// call the debounce fn
			this.fn_debounced()
		},

		// add an intra- or inter-framework association that is coded in the home framework (including sourceItemIdentifier copies)
		add_framework_association_for_framework_menu(associated_framework_id, assoc) {
			// console.log('add_intra_framework_association', assoc.associationType)

			// add the framework to this.assoc_framework_menu_items if not already there 
			if (!this.assoc_framework_menu_items[associated_framework_id]) {
				// look up associated framework
				let associated_framework = this.framework_records.find(x=>x.lsdoc_identifier == associated_framework_id)
				if (!associated_framework) {
					console.log(`update_frameworks_with_associations: couldn’t find framework ${associated_framework_id}`)
					return
				}
				
				let previous_sfwa = this.$store.state.lst.assoc_framework_menu_items4[this.lsdoc_identifier] ?? {}
				this.$set(this.assoc_framework_menu_items, associated_framework_id, {
					showing: (previous_sfwa[associated_framework_id] !== false),	// default to showing associations coded in the home framework
					title: associated_framework.json.CFDocument.title,
					count: 0,
					// note that here we save an array of the relevant associations
					associations: [],
				})
			}

			// make sure we have an associations array for this framework; we might also have a crosswalk for it
			if (!this.assoc_framework_menu_items[associated_framework_id].associations) this.assoc_framework_menu_items[associated_framework_id].associations = []

			// then add the assoc (update_associations_to_show will compute the count)
			this.assoc_framework_menu_items[associated_framework_id].associations.push(assoc)
			// this.assoc_framework_menu_items[associated_framework_id].count = this.assoc_framework_menu_items[associated_framework_id].associations.length
			// console.warn('add_framework_association_for_framework_menu', this.assoc_framework_menu_items[associated_framework_id].count)
		},

		toggle_all_association_frameworks(all_showing) {
			if (typeof(all_showing) != 'boolean') all_showing = this.all_associations_showing
			
			for (let lsdoc_id in this.assoc_framework_menu_items) {
				this.assoc_framework_menu_items[lsdoc_id].showing = !all_showing
				// when you toggle all frameworks, also toggle all types
				this.toggle_all_association_types(!all_showing)
			}
			// watcher will automatically call update_associations_to_show
		},
		
		toggle_all_association_types(all_showing) {
			if (typeof(all_showing) != 'boolean') all_showing = !this.all_association_types_showing

			for (let type in this.assoc_type_menu_options) {
				this.assoc_type_menu_options[type].showing = all_showing
			}
			this.update_associations_to_show()
		},

		association_type_used(type) {
			for (let lsdoc_id in this.assoc_framework_menu_items) {
				for (let assoc of (this.assoc_framework_menu_items[lsdoc_id].associations || [])) {
					if (assoc.associationType == type) return true
				}

				for (let assoc of (this.assoc_framework_menu_items[lsdoc_id].crosswalk?.json.CFAssociations || [])) {
					if (assoc.associationType == type) return true
				}
			}
		},

		// When showing the crosswalk editor/viewer, we include this mixin,
		// then call set_associations_to_show_for_crosswalk to initialize assoc_type_menu_options for the right framework,
		// then call update_associations_to_show_for_crosswalk as we add/remove associations
		set_associations_to_show_for_crosswalk() {
			this.associations_mixin_in_crosswalk = true

			// show all association types we use in the crosswalk tool
			let options = {}
			for (let type of ['isRelatedTo', 'exactMatchOf', 'ext:isNearExactMatch', 'ext:isCloselyRelatedTo', 'ext:isModeratelyRelatedTo', 'ext:hasNoMatch']) {
				options[type] = {
					name: this.$store.state.association_type_labels[type],
					used: true,
					showing: true,
					count: 0,
				}
			}
			this.assoc_type_menu_options = options
			
			// this.b_identifier (in CrosswalkEditor computed) is the framework we're crosswalking to (the right framework)
			this.assoc_framework_menu_items = {}
			let o = {
				showing: true,
				title: this.framework_records.find(x=>x.lsdoc_identifier==this.b_identifier)?.json.CFDocument.title ?? '???',
				has_assocs: false,
				associations: [],
				count: 0,
				crosswalk: this.crosswalk_framework_record,
				load_now: false,
			}
			this.$set(this.assoc_framework_menu_items, this.b_identifier, o)

			this.update_associations_to_show_for_crosswalk()
		},

		update_associations_to_show_for_crosswalk() {
			let option = this.assoc_framework_menu_items[this.b_identifier]

			let new_count = 0
			let hash = {}
			for (let assoc of this.crosswalk_framework_record.json.CFAssociations) {
				if (!this.assoc_type_menu_options[assoc.associationType]) {
					continue
				}
				option.has_assocs = true
				new_count += 1
				this.assoc_type_menu_options[assoc.associationType].count += 1

				if (!hash[assoc.destinationNodeURI.identifier]) hash[assoc.destinationNodeURI.identifier] = []
				hash[assoc.destinationNodeURI.identifier].push(assoc)

				if (!hash[assoc.originNodeURI.identifier]) hash[assoc.originNodeURI.identifier] = []
				hash[assoc.originNodeURI.identifier].push(assoc)
			}

			// store this hash in displayed_associations_hash for the left framework record
			this.$store.commit('set', [this.framework_record_a.cfo, 'displayed_associations_hash', hash])

			// get counts for folders
			vapp.$store.commit('set', [this.framework_record_a.cfo, 'displayed_association_counts_hash', U.get_association_counts_hash(this.framework_record_a.cfo)])
		},

	}
}

/*
abandoned code for getting copied items -- we could bring this back later...

			let process_copies = (keys) => {
				do {
					// shift the next original_id from keys, and create the "stub assoc" for this item
					let original_id = keys.shift()
					let a = {
						associationType: 'copiedFromSource',
						originNodeURI: { identifier: this.cfo.sourceItemIdentifier_hash[original_id], title: '' },
						destinationNodeURI: { identifier: original_id, title: '' },
					}
	
					// try to look the original_id up in frameworks we've already loaded
					let framework_id
					for (let fr of this.$store.state.framework_records) {
						if (fr.cfo && fr.cfo.cfitems && fr.cfo.cfitems[item_identifier]) {
							framework_id = fr.lsdoc_identifier
							break
						}
					}

					// if we did find it, add to assoc_framework_menu_items and go on to the next item 
					if (framework_id) {
						this.add_framework_association_for_framework_menu(framework_id, a)
					} else {
						// if we didn't find it, look the item identifier up using the service
						U.get_lsdoc_identifier_from_item_identifier(original_id, a).then(framework_id=>{
							// and if we found the framework_id this way, get the whole framework now. 
							// we do it this way because if we've copied one item from a framework, we've probably copied a lot of items from the same framework, and by retrieving the framework here...
							U.loading_start('Loading framework…')
							this.$store.dispatch('get_lsdoc', lsdoc_identifier).then(()=>{			
								// then build the cfo for the framework
								let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
								U.build_cfo(this.$worker, fr.json).then((cfo)=>{
									this.$store.commit('set', [fr, 'cfo', cfo])
									this.$store.commit('set', [fr, 'framework_json_loading', false])
									U.loading_stop()
				
									// after loading a framework, add to assoc_framework_menu_items and call process_copies to keep processing
									this.add_framework_association_for_framework_menu(framework_id, a)
									process_copies(keys)

								}).catch((e)=>{
									this.$store.commit('set', [fr, 'framework_json_load_failed', true])
									this.$store.commit('set', [fr, 'framework_json_loading', false])
									U.loading_stop()
									console.log(e)
								})
				
							}).catch((e)=>{
								console.log(e)
								U.loading_stop()
								// fail silently, but set framework_json_load_failed to true so we don't keep trying to load
								let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
								this.$store.commit('set', [fr, 'framework_json_load_failed', true])
								this.$store.commit('set', [fr, 'framework_json_loading', false])
							})
						})
						// then return; we will re-call process_copies once the asynchronous stuff above finishes
						return
					}
				} while (keys.length > 0)
			}
			let keys = Object.keys(this.cfo.sourceItemIdentifier_hash)
			process_copies(keys)
*/