// U.case_api_url('xxx') -- current domain, CFPackages, v1p0
U.case_api_url = function(identifier, api, format, domain) {
	if (!api) api = 'CFPackages'
	if (!format) format = 'v1p0'
	if (!domain) domain = vapp.$store.state.framework_domain	// window.location.origin
	return sr('$1/ims/case/$2/$3/$4', domain, format, api, identifier)
}

// call_case_api should be used like this:
// let url = U.case_api_url(identifier, 'CFItems')
// e.g. https://satchel.commongoodlt.com/ims/case/v1p0/CFItems/6175b75f-1d7a-49d9-8949-0b02e0475c2b
// U.call_case_api(url, (status, result)=>{
// 	// if status isn't 'ok', it's an error, with error data in result; call_case_api may have console.logged more info
// 	if (status != 'ok') {	// 'error' or 'ims_error'
// 		...
// 	} else {
// 		// else we should have received the relevant json, so proceed to do something with it...
//		...
// 	}
// })
// .... BUT IT DOESN'T WORK if you call it for an API on another server...
U.call_case_api = function(url, callback_fn) {		// modeled on U.ajax
	let options = {
		type: 'GET',
		url: url,
		cache: false,
		dataType: 'text',
		success: function(str, text_status) {
			let result
			if (empty(str)) {
				result = 'CASE API returned with no status'
			} else {
				try {
					result = JSON.parse(str)
				} catch(e) {
					result = 'CASE API return value could not be parsed: ' + str
				}
			}

			// if result isn't an object, return it
			if (typeof(result) != 'object') {
				console.log(result)
				callback_fn('error', result)
				return
			}

			// if we got an error, return it
			if (result.imsx_codeMajor) {
				console.log('CASE API returned error', result)
				// send ims_error as first value, in case the callback_fn wants to parse the error data
				callback_fn('ims_error', result)
				return
			}

			// success: return 'ok' and result, which should be CASE JSON (it makes no sense to call this without a callback_fn)
			callback_fn('ok', result)
		},
		error: function(jqXHR, textStatus, errorThrown) {
			let result = {
				'status': 'CASE API network error',
				'textStatus': textStatus,
				'errorThrown': errorThrown,
				'responseText': jqXHR.responseText
			}
			console.log(result)
			callback_fn('error', result)
		}
	}

	$.ajax(options)
}

// given an item_identifier, return the lsdoc_identifier that houses the item
// note that you can alternatively dispatch the service alone
U.get_lsdoc_identifier_from_item_identifier = function(item_identifier, data_to_return) {
	return new Promise((resolve, reject)=>{
		// first try to look the identifier up in frameworks we've already loaded
		for (let fr of vapp.$store.state.framework_records) {
			if (fr.cfo && fr.cfo.cfitems && fr.cfo.cfitems[item_identifier]) {
				// found it
				// console.log(`get_lsdoc_identifier_from_item_identifier: FOUND WITHOUT CALLING SERVICE: ${item_identifier} (${data_to_return?.associationType})`)
				resolve(fr.lsdoc_identifier, data_to_return)
				return
			}
		}
		// console.log(`get_lsdoc_identifier_from_item_identifier: HAD TO CALL SERVICE: ${item_identifier} (${data_to_return?.associationType})`)
					
		// not found in frameworks we've already loaded, so try to get the corresponding doc for the item identifier
		vapp.$store.dispatch('get_lsdoc_identifier_from_item_identifier', item_identifier).then((lsdoc_identifier)=>{
			// found it
			resolve(lsdoc_identifier, data_to_return)
		}).catch((e)=>{
			reject(data_to_return)
		})
	})
}

U.parse_framework_category = function(category) {
	if (empty(category)) category = ''
	// extract sequence_number and map_label if there
	let t1 = category.replace(/^(\d+)\s*/, '')
	let sequence_number = (t1 == category) ? '' : RegExp.$1
	let title = t1.replace(/\s*\[\[(.+)\]\]$/, '')
	let map_label = (title == t1) ? '' : RegExp.$1
	if (!title) title = '—'

	return {
		title: title,
		sequence_number: sequence_number,
		map_label: map_label,
	}
}

U.framework_title_with_category = function(framework_record) {
	let title = framework_record.json.CFDocument.title
	let co = U.parse_framework_category(framework_record.ss_framework_data.category)
	if (co.map_label) title = `${co.map_label}: ${title}`
	else if (co.title && co.title != '—' && co.title.length < 5) title = `${co.title}: ${title}`

	return title
}

// fn to create category_records structure; used from FrameworkList and FrameworkSwitcher, with possibly-different sets of included_categories
// note that state.framework_records must be loaded before this is called
U.create_category_records = function(show_sandboxes, show_mirrors_only) {
	let arr = []

	// go through all available_framework_records
	let cr_index = 0
	for (let framework_record of vapp.$store.getters.filtered_framework_records) {
		let ls_doc = framework_record.json.CFDocument

		// if show_mirrors_only is true, only include mirrors
		if (show_mirrors_only === true && framework_record.ss_framework_data.is_mirror != 'yes') continue

		// and if show_sandboxes is false, skip sandboxes
		if (show_sandboxes != true && !empty(framework_record.ss_framework_data.sandboxOfIdentifier)) continue

		// if framework title hasn't been set, it's a new document being created, so don't show it
		if (!ls_doc.title) continue

		// try to get an entry for the framework_record's category
		let category = framework_record.ss_framework_data.category

		let cr = arr.find(x=>x.category==category)
		if (empty(cr)) {
			// if not found, create
			++cr_index

			let category_data = U.parse_framework_category(category)

			cr = {
				index: cr_index,
				category: category,
				title: (category_data.title == '—' ? 'Other Frameworks' : category_data.title),
				sequence_number: category_data.sequence_number,
				map_label: category_data.map_label,
				framework_records: [],
				showing: true,	// if this is false, the category's "tile" is not showing at all (this is manipulated by the search mechanism)
				doc_showing: {},		// this holds whether or not each document's title should be showing (this is manipulated by the search mechanism)
			}
			arr.push(cr)
		}
		// push ls_doc onto cr's framework_records
		cr.framework_records.push(framework_record)
		cr.doc_showing[ls_doc.identifier] = true
	}

	// sort categories, with default on bottom
	let home_category = vapp.$store.state.site_config.home_category
	let hc_search = '[[' + home_category + ']]'	// note that home_category should specify a map_label
	arr.sort((a,b) => {
		// exception: if site_config.home_category is defined, put that category first
		if (home_category && a.category.indexOf(hc_search) > -1) return -1
		if (home_category && b.category.indexOf(hc_search) > -1) return 1

		if (a.category == '') return 1
		if (b.category == '') return -1

		return U.natural_sort(a.category, b.category)
	})

	// sort each cr's framework_records
	for (let cr of arr) {
		cr.framework_records.sort((a,b) => {
			if (a.json.CFDocument.title < b.json.CFDocument.title) return -1
			if (b.json.CFDocument.title < a.json.CFDocument.title) return 1
			return 0
		})
	}

	return arr
}

U.recent_framework_category = function(show_sandboxes) {
	let cr = {
		index: -1,
		category: 'RECENT',
		title: 'Recently-accessed frameworks',
		sequence_number: -1,
		map_label: '',
		framework_records: [],
		showing: true,	// if this is false, the category's "tile" is not showing at all (this is manipulated by the search mechanism)
		doc_showing: {},		// this holds whether or not each document's title should be showing (this is manipulated by the search mechanism)
	}
	for (let framework_identifier of vapp.$store.state.lst.recent_frameworks) {
		let framework_record = vapp.$store.getters.filtered_framework_records.find(x=>x.lsdoc_identifier == framework_identifier)
		if (!framework_record) continue
		if (show_sandboxes != true && !empty(framework_record.ss_framework_data.sandboxOfIdentifier)) continue

		// if we get to here add to category
		cr.framework_records.push(framework_record)
		cr.doc_showing[framework_record.ls_doc_identifier] = true
	}
	// leave frameworks in the order they appear in lst.recent_frameworks
	return cr
}

U.add_to_recent_frameworks = function(framework_identifier) {
	let rf = vapp.$store.state.lst.recent_frameworks

	// splice from list if there
	let i = rf.findIndex(x=>x==framework_identifier)
	if (i > -1) rf.splice(i, 1)

	// unshift to put fi at the start
	rf.unshift(framework_identifier)

	// if we have more than 10 items in the list, pop
	if (rf.length > 10) rf.pop()
	vapp.$store.commit('lst_set', ['recent_frameworks', rf])
}

U.framework_subject_key = [
	{subject_guess_string:'English', label:'English Language Arts', sort_index:1, icon_index:4, color_class:'k-framework-color-1'},
	{subject_guess_string:'Math', label:'Math', sort_index:2, icon_index:2, color_class:'k-framework-color-2'},
	{subject_guess_string:'Science', label:'Science', sort_index:3, icon_index:6, color_class:'k-framework-color-3'},
	{subject_guess_string:'Social Studies', label:'Social Studies', sort_index:4, icon_index:11, color_class:'k-framework-color-4'},
	{subject_guess_string:'Languages', label:'World Languages', sort_index:5, icon_index:7, color_class:'k-framework-color-5'},
	{subject_guess_string:'Computer Science', label:'Computer Science', sort_index:6, icon_index:3, color_class:'k-framework-color-6'},
	{subject_guess_string:'Arts', label:'Fine Arts', sort_index:7, icon_index:5, color_class:'k-framework-color-7'},
	{subject_guess_string:'Health', label:'Health', sort_index:8, icon_index:9, color_class:'k-framework-color-8'},
	{subject_guess_string:'PE', label:'Physical Education', sort_index:9, icon_index:10, color_class:'k-framework-color-9'},
	{subject_guess_string:'CTE', label:'Career/Tech', sort_index:10, icon_index:1, color_class:'k-framework-color-10'},
	{subject_guess_string:'xx', label:'Other', sort_index:11, icon_index:0, color_class:'k-framework-color-11'},
]

U.framework_subject_guess = function(cfdoc) {
	// guess at a standardized subject for the framework; returns
	let icon_index = -1
	if (!empty(cfdoc)) {
		let subject
		// start by getting the (first) subject, if we have one, or the title
		let subjects = U.entity_subject_string_array(cfdoc)
		if (subjects[0]) subject = subjects[0]
		else subject = cfdoc.title + ''

		// look for keywords in the subject value we just got
		// look for key words that we *don't* want to match on
		if (subject.indexOf('Development') > -1) subject = 'Other'
		else if (subject.indexOf('CTE') > -1 || subject.indexOf('CTAE') > -1) subject = 'CTE'
		else if (subject.indexOf('English') > -1 || subject.indexOf('Language Arts') > -1 || subject.indexOf('ELA') > -1) subject = 'English'
		else if (subject.indexOf('Health') > -1) subject = 'Health'
		else if (subject.indexOf('Physical') > -1 || subject.indexOf('PE') > -1) subject = 'PE'
		else if (subject.indexOf('Language') > -1) subject = 'Languages'
		else if (subject.indexOf('Math') > -1) subject = 'Math'
		else if (subject.indexOf('Social') > -1) subject = 'Social Studies'
		else if (subject.indexOf('for Science') > -1) subject = 'Science'	// deals with idiosynchrasy for Indiana
		else if (subject.indexOf('Computer') > -1) subject = 'Computer Science'
		else if (subject.indexOf('Science') > -1) subject = 'Science'
		else if (subject.search(/(Dance|Music|Theatre|Art)/) > -1) subject = 'Arts'

		// look for the value we ended up with in a list of known subject values; this list is constructed to match up with the default icons in framework_icons.js
		// note that icon_index will be -1 if this fails; also note that the 'xx' value is for the now-largely-unused original math image
		icon_index = ['CTE', 'Math', 'Computer Science', 'English', 'Arts', 'Science', 'Languages', 'xx', 'Health', 'PE', 'Social Studies'].indexOf(subject) + 1
		// note the '+ 1' at the end; that makes the lowest possible value 0
	}

	// return framework_subject_key object
	return U.framework_subject_key.find(x=>x.icon_index == icon_index)
}

U.framework_color = function(framework_record) {
	// if framework_record comes in as a string, it's an identifier; look up the corresponding framework_record from store
	if (typeof(framework_record) == 'string') framework_record = vapp.$store.state.framework_records.find(x=>x.lsdoc_identifier == framework_record)

	// if we don't have a framework record (which shouldn't happen), or single_color_scheme_on is true (which could happen), always use color '0'
	if (empty(framework_record) || vapp.single_color_scheme_on) return 'k-framework-color-0'

	// if ss_framework_data.color is '0'...
	let color = framework_record.ss_framework_data.color
	if (color == '0') {
		// pick a color based on the subject, if we have it
		let subject = U.framework_subject_guess(framework_record.json.CFDocument)
		if (subject) return subject.color_class

		// otherwise base on document identifier
		color = 1
		for (let i = 0; i < framework_record.lsdoc_identifier.length; ++i) {
			color += framework_record.lsdoc_identifier.charCodeAt(i)
		}
		// don't use grey (color 14)
		color = (color % 13) + 1
	}
	return 'k-framework-color-' + color
}

U.framework_image_src = function(framework_record) {
	// if ss_framework_data.image is a number, we're using a standard image, so return it
	let image_int = framework_record.ss_framework_data.image*1
	if (!isNaN(image_int)) {
		// if the image int is 0, try to choose an icon based on the subject
		if (image_int == 0) {
			image_int = U.framework_subject_guess(framework_record.json.CFDocument).icon_index
			// for math, use 8 instead of 2
			if (image_int == 2) image_int = 8
		}
		let o = U.framework_icons.find(x=>x.id == image_int)
		if (!o) o = U.framework_icons[0]
		return o.img
	} else {
		// otherwise return the image value, which should be a data-url
		return framework_record.ss_framework_data.image
	}
}

// given a framework record, an archived version of the framework record, and an identifier, return what the entity (document or item) was before and what it is now
// note that this should only be called if the viewer is actually showing an archive in track changes mode
U.get_archive_comparison_entities = function(identifier, framework_record, archive_framework_record) {
	// note that if current/archived includes an identifier, that's the signal to other parts of the system that the item exists in the current or archived framework
	let a, b, current, archived
	if (identifier == framework_record.lsdoc_identifier) {
		// the document has to be in both places
		current = { identifier: identifier }
		archived = { identifier: identifier }
		a = new CFDocument(framework_record.json.CFDocument)
		b = new CFDocument(archive_framework_record.json.CFDocument)
	
	} else {
		let current_cfitem = framework_record.cfo.cfitems[identifier]
		if (current_cfitem) {
			current = { identifier: identifier }
			a = new CFItem(current_cfitem)
		} else {
			current = {}
			a = {}
		}

		let archived_cfitem = archive_framework_record.cfo.cfitems[identifier]
		if (archived_cfitem) {
			archived = { identifier: identifier }
			b = new CFItem(archived_cfitem)
		} else {
			archived = {}
			b = {}
		}
	}

	for (let key in vapp.$store.state.lst.track_changes_fields) {
		if (vapp.$store.state.lst.track_changes_fields[key]) {
			if (key == 'fullStatement') {
				current.fullStatement = a.fullStatement
				current.abbreviatedStatement = a.abbreviatedStatement
				current.title = a.title
				archived.fullStatement = b.fullStatement
				archived.abbreviatedStatement = b.abbreviatedStatement
				archived.title = b.title
			} else if (key == 'supplementalNotes') {
				current[key] = a.extensions ? a.extensions[key] : ''
				archived[key] = b.extensions ? b.extensions[key] : ''
			} else {
				current[key] = a[key]
				archived[key] = b[key]
			}
		}
	}
	return {current: current, archived: archived}
}

// return a count of the number of descendents of top_node that have changes to the specified fields
U.get_archive_comparison_children_change_count = function(top_node, other_framework_record) {
	let change_count = 0
	let get_child_count = (this_node) => {
		let this_cfitem = this_node.cfitem
		let identifier = this_cfitem.identifier
		let other_cfitem = other_framework_record.cfo.cfitems[identifier]
		// if this item doesn't exist in the other framework, that's a change (it means the item new)
		if (!other_cfitem) {
			++change_count
		} else {
			// it exists in both places, so if any of the track changed fields differ, that's a change
			for (let key in vapp.$store.state.lst.track_changes_fields) {
				if (vapp.$store.state.lst.track_changes_fields[key]) {
					let a = this_cfitem[key], b = other_cfitem[key]
					if (key != 'educationLevel' && vapp.$store.state.lst.track_changes_fields.loose_comparisons) {
						// note that for notes and fullStatement, we have to run this through marked before alphanum_only so that we ignore markdown changes
						if (key == 'fullStatement' || key == 'notes') {
							a = marked(a)
							b = marked(b)
						}
						a = U.alphanum_only(a)
						b = U.alphanum_only(b)
					}
					if (a != b) { ++change_count; break; }
				}
			}
		}

		// count for children
		for (let child of this_node.children) {
			get_child_count(child)
		}
	}

	// don't count top_node itself; just count for it's children (so we don't have to worry about the document)
	for (let child of top_node.children) {
		get_child_count(child)
	}

	return change_count
}

// return the icon to show for a set of archive_comparison_entities (as created using the fn above)
U.tracked_change_icon = function(tracked_change_entities, top_node, other_framework_record) {
	// item is in the current framework, but not in the archived framework, so it was added
	if (!tracked_change_entities.archived.identifier) return 'fa-circle-plus'
	// item is in the archived framework, but not in the new framework, so it was deleted
	if (!tracked_change_entities.current.identifier) return 'fa-circle-xmark'

	// compare only fields that user has chosen to pay attention to, and use loose comparisons if specified
	let changed = false
	for (let key in vapp.$store.state.lst.track_changes_fields) {
		if (vapp.$store.state.lst.track_changes_fields[key]) {
			let a = tracked_change_entities.current[key], b = tracked_change_entities.archived[key]
			if (key != 'educationLevel' && vapp.$store.state.lst.track_changes_fields.loose_comparisons) {
				// note that for notes and fullStatement, we have to run this through marked before alphanum_only so that we ignore markdown changes
				if (key == 'fullStatement' || key == 'notes') {
					a = marked(a)
					b = marked(b)
				}
				a = U.alphanum_only(a)
				b = U.alphanum_only(b)
			}
			if (a != b) { changed = true; break; }
		}
	}

	if (changed) {
		return 'fa-circle-half-stroke'
	} else {
		// if we received a top_node and other_framework_record, use a different icon if any of this item's children have changed
		if (top_node && U.get_archive_comparison_children_change_count(top_node, other_framework_record) > 0) {
			return 'fa-circle-play'	// this will be rotated 90deg by css
		} else {
			return 'fa-circle'
		}
	}
}

// unfortunately, an item's type can be in either CFItemType or CFItemTypeURI.title; normalize this to a string
U.item_type_string = function(item) {
	let type = item.CFItemType ? item.CFItemType : (item.CFItemTypeURI ? item.CFItemTypeURI.title : '')
	return type
}

// unfortunately, a document's/item's subject can be in either subject or subjectURI.title
// also the both fields are supposed to be arrays, but I've seen subject coded as a string
// this fn returns a consistent array of strings
U.entity_subject_string_array = function(document) {
	if (empty(document)) return []
	let subject = (document.subject && document.subject.length > 0) ? document.subject : ''
	if (!subject) subject = (document.subjectURI && ($.isArray(document.subjectURI) || typeof(document.subjectURI) == 'object') ? document.subjectURI : '')
	if (!subject) return []

	if (!$.isArray(subject)) {
		subject = [subject]
	}

	let arr = []
	for (let s of subject) {
		if (typeof(s) == 'string') arr.push(s)
		else arr.push(s.title)
	}

	return arr
}

U.item_is_copy = function(item) {
	// an item is a copy of another item if it has a sourceItemIdentifier that isn't identical to the identifier
	return item.extensions?.sourceItemIdentifier && item.extensions.sourceItemIdentifier != item.identifier
}

// this fn removes line breaks from a text field, with an attempt to preserve just those line breaks that would be meaningful in markdown.
// to do a more brute-force removal of line breaks and cleanup of spacing:
// str = $.trim(str.replace(/\s+/g, ' '))
U.remove_line_breaks_from_text_field = function(s) {
	if (empty(s) || typeof(s) != 'string') return s

	// remove line breaks from text fields, unless they look like they might be the starts of markup lines
	let lines = s.split('\n')
	s = ''
	for (let line of lines) {
		let trim_line = $.trim(line)

		// pass completely blank lines through as double-\n's, to preserve markdown line breaks
		if (!trim_line) {
			s += '\n\n'
			continue
		}

		// if the first non-space char in the line isn't one of the characters that have special significance in a markdown line,
		// (second regex checks for i., ii., iv., x, etc.; second for a., B., etc.)
		if (trim_line.search(/^[-*+#_>|\d]/) == -1 && trim_line.search(/^[ivx]+\. /) == -1 && trim_line.search(/^[a-z]\. /i) == -1) {
			// if s currently doesn't end with a line break, add a space to separate this line's text from the previous line's text
			if (s.search(/\n$/) == -1) s += ' '

			// then add the trimmed line without a line break
			s += trim_line

		} else {
			// else it's supposed to start a new line, so add the non-trimmed line, preceded by a line break
			s += '\n' + line
		}
	}

	// now replace two or more spaces with one space, unless the spaces precede a * (space-space-star means a second-level list item)
	s = s.replace(/  +([^*])/g, ' $1')
	// and three or more line breaks with two line breaks
	s = s.replace(/\n\n\n+/g, '\n\n')

	return $.trim(s)
}

// convert educationLevel array to a string (e.g. '9-12'), using `grades` array as the ordered list of possible educationLevels
U.grade_level_display = function(educationLevel, grades, separator) {
	if (empty(educationLevel) || educationLevel.length == 0) return ''

	let s = ''

	// if grades not supplied, use vapp.$store.state.grades
	if (!grades) grades = vapp.$store.state.grades

	let el = educationLevel[0]
	let grade = grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
	if (!empty(grade)) s = grade.text
	else s = el

	if (educationLevel.length == 1) return s

	// caller can optionally send in a separator; by default we just use a plain hyphen
	s += (separator) ? separator : '-'

	el = educationLevel[educationLevel.length - 1]
	grade = grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
	if (!empty(grade)) s += grade.text
	else s += el

	return s
}

// convert from grade_level_display format back to an educationLevel array
U.grade_level_display_to_educationLevel = function(gld, grades, separator) {
	if (empty(gld)) return []

	// caller can optionally send in a separator; by default assume a plain hyphen
	if (!separator) separator = '-'

	// if grades not supplied, use vapp.$store.state.grades
	if (!grades) grades = vapp.$store.state.grades

	let arr = gld.split(separator)
	let lo = $.trim(arr[0])
	let hi = $.trim(arr[1])

	// convert 'K' to 'KG'
	if (lo == 'K') lo = 'KG'
	if (hi == 'K') hi = 'KG'

	let lo_index = grades.findIndex(x=>x.value == lo)
	let hi_index = grades.findIndex(x=>x.value == hi)

	// if we didn't get a lo_index, nothing to parse
	if (lo_index == -1) return []

	// if we have no hi value, it's a single grade
	if (!hi_index) {
		return [grades[lo_index].value]
	}

	// if we get to here process range
	if (lo_index > hi_index) {
		let temp = lo_index
		lo_index = hi_index
		hi_index = temp
	}

	arr = []
	for (let i = lo_index; i <= hi_index; ++i) {
		arr.push(grades[i].value)
	}

	return arr
}

U.case_uri_html = function(o) {
	return sr('$1 (<a href="$2" target="_blank">$3</a>)', o.title, o.uri, o.identifier)
}

U.case_array_html = function(arr) {
	return arr.join(', ')
}

U.case_array_of_uris_html = function(arr) {
	let a2 = []
	for (let uri of arr) {
		a2.push(U.case_uri_html(uri))
	}
	return U.case_array_html(a2)
}

U.case_field_value_html = function(field, val) {
	if ($.isArray(val)) {
		if (field.indexOf('URI') > -1) {
			return U.case_array_of_uris_html(val)
		} else {
			return U.case_array_html(val)
		}
	} else {
		if (field.indexOf('URI') > -1) {
			return U.case_uri_html(val)
		} else {
			return val
		}
	}
}

// for functionality where we need to do comparisons, it's helpful to be able to move the extensions fields out to the main object
// this also creates a deep copy of the object.
U.flatten_extensions = function(obj) {
	let nobj = $.extend(true, {}, obj)
	if (nobj.extensions) {
		for (let key in nobj.extensions) {
			nobj[key] = nobj.extensions[key]
		}
		delete nobj.extensions
	}
	return nobj
}

U.case_current_time_string = function() {
	// current time, GMT, in format for lastChangeDateTime
	return date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+00:00', true)
}

U.local_last_change_date_time = function(s) {
	// convert given lastChangeDateTime string to the local time zone, and format nicely
	return date.format(new Date(s), 'YYYY-MM-DD HH:mm')
}

U.get_license_json = function(framework_record) {
	if (framework_record.json.CFDocument.licenseURI) {
		// look for the license in the framework's CFDefinitions.CFLicenses area
		let licenses = oprop(framework_record.json, 'CFDefinitions', 'CFLicenses')
		if (licenses && licenses[0]) {
			return licenses[0]
			// return value should include 'title' and licenseText
		}
	}
	return {}
}

U.generate_document_uri = function(doc) {
	return new LinkURI({
		title: doc.title,
		identifier: doc.identifier,
		uri: doc.uri,
	})
}

U.generate_child_uri = function(doc, identifier, service) {
	// replace the document identifier for the incoming identifier in the document's uri; then replace "CFDocuments" with the incoming service value
	// so, e.g., `https://case.georgiastandards.org/ims/case/v1p0/CFPackages/00fcf0e2-b9c3-11e7-a4ad-47f36833e889` becomes `https://case.georgiastandards.org/ims/case/v1p0/CFItems/00ad9d88-192b-11eb-8deb-0242ac130004`
	return doc.uri.replace(doc.identifier, identifier).replace(/(CFPackages|CFDocuments)/, service)
}

// generate the best value for the "title" field of a destinationNodeURI or originNodeURI for a CFAssociation; the incoming item could be either a CFItem or a CFDocument
// this is also used in other situations for generating the corresponding value
U.generate_cfassociation_node_uri_title = function(item, length) {
	// if length === true, it means to generate the full title (for a document) or hcs+fullstatement
	if (length === true) {
		if (!empty(item.title)) return item.title
		if (!empty(item.humanCodingScheme)) return item.humanCodingScheme + ' ' + item.fullStatement
		return item.fullStatement
	}

	let s = ''
	if (!empty(item.humanCodingScheme)) s = item.humanCodingScheme + ' '
	if (!empty(item.fullStatement)) s += item.fullStatement
	if (!empty(item.title)) s = item.title
	if (empty(s)) s = '???'
	s = $.trim(s)

	// if length is false or isn't set, use default 20 characters
	if (!length || typeof(length) != 'number') length = 20
	// use max length characters, to keep file sizes small
	if (s.length > length) s = s.substr(0,length) + '…'
	return s
}

U.generate_cfassociation_node_uri_title_with_framework_identifier = function(item, length, framework_identifier) {
	// note: currently (6/30/2024), there are a number of places that don't use this fn for doing this; those should be changed to use this fn
	return `${U.generate_cfassociation_node_uri_title(item, length)} (:${framework_identifier}:)`
}

U.get_framework_identifier_from_cfassociation_node_uri_title = function(title) {
	if (title && title.search(/\(:(\S*):\)$/) > -1) {
		return RegExp.$1
	}
	return null
}

U.create_association = function(dest_cfitem, origin_cfitem, sequenceNumber, cfdocument, association_type) {
	if (empty(association_type)) association_type = 'isChildOf'
	// note that:
	//     for xNodeURI titles, we use the humanCodingScheme (if there) or the first X chars of the fullStatement
	//     the origin (of "isChildOf") is the child (the item we're saving)
	//     the destination is the child's parent

	// let association_identifier = U.new_uuid()
	// let date_string = new Date().toISOString().replace(/\.\d+Z/, '+00:00')	// 2020-12-17T01:49:46+00:00
	let dest_title = U.generate_cfassociation_node_uri_title(dest_cfitem)
	let origin_title = U.generate_cfassociation_node_uri_title(origin_cfitem)

	let a = new CFAssociation({
		originNodeURI: {
			title: origin_title,
			identifier: origin_cfitem.identifier,
			uri: origin_cfitem.uri
		},

		associationType: association_type,

		destinationNodeURI: {
			title: dest_title,
			identifier: dest_cfitem.identifier,
			uri: dest_cfitem.uri
		},
	})
	if (sequenceNumber != null) a.sequenceNumber = sequenceNumber

	// complete the item, filling in identifier, uri, and lastChangeDateTime
	a.complete_data(cfdocument)

	return a
}

// the following three functions are used when deleting items (including new items that have been temporarily added when creating new items)
U.delete_node_from_cfo = function(cfo, node, preserve_cfitem) {
	// // if the to-be-deleted node has any children, we need to delete them first
	// if (node.children && node.children.length > 0) {
	// 	for (let child of node.children) {
	// 		U.delete_node_from_cfo(cfo, child, preserve_cfitem)
	// 	}
	// }

	let cfitem = node.cfitem

	// the deleted item's parent may exist in multiple places, so deal with it in all such parent nodes
	// for each parent_node ...
	let parent_nodes = node.parent_node.cfitem.tree_nodes
	for (let parent_node of parent_nodes) {
		// get the index of the child node with this node's cfitem (it should always be there)
		let index = parent_node.children.findIndex(x=>x.cfitem.identifier == cfitem.identifier)
		if (index > -1) {
			let this_child = parent_node.children[index]

			// splice the child node out of the parent_node's children
			vapp.$store.commit('set', [parent_node.children, 'SPLICE', index])

			// and remove the child node from the cfitem's tree_nodes array
			let index2 = cfitem.tree_nodes.findIndex(x=>x.tree_key == this_child.tree_key)
			if (index2 > -1) {
				vapp.$store.commit('set', [cfitem.tree_nodes, 'SPLICE', index2])
			}

			// remove from cfo.tree_nodes_hash
			vapp.$store.commit('set', [cfo.tree_nodes_hash, this_child.tree_key+'', '*DELETE_FROM_STORE*'])

			// remove associations from associations_hash, including aliases and copies
			if (cfo.associations_hash[cfitem.identifier]) {
				for (let assoc of cfo.associations_hash[cfitem.identifier]) {
					U.remove_from_associations_hash(cfo, assoc)
				}
			}
		}
	}

	// if preserve_cfitem isn't true...
	if (preserve_cfitem !== true) {
		// if the cfitem has no tree_nodes left, remove from cfitems
		if (cfitem.tree_nodes.length == 0) {
			// remove from cfitems
			vapp.$store.commit('set', [cfo.cfitems, cfitem.identifier, '*DELETE_FROM_STORE*'])
		}
	}
}

U.delete_item_from_json = function(framework_record, identifier) {
	let i = framework_record.json.CFItems.findIndex(x=>x.identifier == identifier)
	if (i > -1) {
		vapp.$store.commit('set', [framework_record.json.CFItems, 'SPLICE', i])
	}
}

U.delete_association_from_json = function(framework_record, identifier) {
	let i = framework_record.json.CFAssociations.findIndex(x=>x.identifier == identifier)
	if (i > -1) {
		vapp.$store.commit('set', [framework_record.json.CFAssociations, 'SPLICE', i])
	}
}

// this fn recursively gets a count of each item's associations, *including associations in the item's children*, for the *displayed* associations (displayed_associations_hash)
// We use this for display purposes; doing the calculation centrally saves a lot of rendering time
U.get_association_counts_hash = function(cfo) {
	let hash = {}
	let get_association_counts = (cfo, node) => {
		// look in cfo.displayed_associations_hash for this item's non-isChildOf associations
		let arr = cfo.displayed_associations_hash[node.cfitem.identifier]
		let count = 0

		// don't "double count" assocs to/from the same items
		let other_identifiers = ''
		if (arr) for (let i = 0; i < arr.length; ++i) {
			let other_identifier = arr[i].destinationNodeURI.identifier
			if (other_identifier == node.cfitem.identifier) other_identifier = arr[i].originNodeURI.identifier
			if (other_identifiers.indexOf(other_identifier) == -1) {
				++count
				other_identifiers += '+' + other_identifier
			}
		}

		// let count = (arr) ? arr.length : 0
		for (let i = 0; i < node.children.length; ++i) {
			count += get_association_counts(cfo, node.children[i])
		}
		hash[node.cfitem.identifier] = count
		return count
	}

	get_association_counts(cfo, cfo.cftree)
	return hash
}

// add/update an association in the cfo's associations_hash (the actual hash, not the display hash; the display hash is handled in update_associations_to_show)
U.update_associations_hash = function(cfo, assoc, suppress_get_association_counts_hash) {
	if (!cfo?.associations_hash) {
		console.log('no cfo / associations_hash')
		return
	}
	let associations_hash = cfo.associations_hash

	// if an array for the destination identifier's associations in the associations_hash doesn't already exist, create the array
	if (!associations_hash[assoc.destinationNodeURI.identifier]) {
		vapp.$store.commit('set', [associations_hash, assoc.destinationNodeURI.identifier, []])
	}
	// if an assoc with this association identifier already exists, update it; otherwise push onto the array
	let index = associations_hash[assoc.destinationNodeURI.identifier].findIndex(x=>x.identifier == assoc.identifier)
	if (index == -1) {
		vapp.$store.commit('set', [associations_hash[assoc.destinationNodeURI.identifier], 'PUSH', assoc])
	} else {
		vapp.$store.commit('set', [associations_hash[assoc.destinationNodeURI.identifier], 'SPLICE', index, assoc])
	}

	// repeat for the origin identifier -- except for aliasOf
	if (assoc.associationType != 'aliasOf') {
		if (!associations_hash[assoc.originNodeURI.identifier]) {
			vapp.$store.commit('set', [associations_hash, assoc.originNodeURI.identifier, []])
		}
		let index = associations_hash[assoc.originNodeURI.identifier].findIndex(x=>x.identifier == assoc.identifier)
		if (index == -1) {
			vapp.$store.commit('set', [associations_hash[assoc.originNodeURI.identifier], 'PUSH', assoc])
		} else {
			vapp.$store.commit('set', [associations_hash[assoc.originNodeURI.identifier], 'SPLICE', index, assoc])
		}
	}

	// update displayed_association_counts_hash, unless suppress_get_association_counts_hash is true, in which case the caller should do this
	// SHOULDN'T NEED TO DO THIS ANYMORE HERE
	// if (!suppress_get_association_counts_hash) {
	// 	vapp.$store.commit('set', [cfo, 'displayed_association_counts_hash', U.get_association_counts_hash(cfo)])
	// }
}

// remove association from cfo's associations_hash
U.remove_from_associations_hash = function(cfo, assoc) {
	if (!cfo?.associations_hash) {
		console.log('no cfo / associations_hash')
		return
	}
	let associations_hash = cfo.associations_hash

	// if an array for the destination identifier's associations exists,
	let hash = associations_hash[assoc.destinationNodeURI.identifier]
	if (hash) {
		// look for the given assoc in the array, and splice from array if found
		let index = hash.findIndex(x=>x.identifier == assoc.identifier)
		if (index > -1) {
			vapp.$store.commit('set', [hash, 'SPLICE', index])
		}
	}

	// repeat for the origin identifier
	hash = associations_hash[assoc.originNodeURI.identifier]
	if (hash) {
		// look for the given assoc in the array, and splice from array if found
		let index = hash.findIndex(x=>x.identifier == assoc.identifier)
		if (index > -1) {
			vapp.$store.commit('set', [hash, 'SPLICE', index])
		}
	}

	// update displayed_association_counts_hash
	// SHOULDN'T NEED TO DO THIS ANYMORE HERE
	// vapp.$store.commit('set', [cfo, 'displayed_association_counts_hash', U.get_association_counts_hash(cfo)])
}

U.pull_associations_from_original_of_derivative = function(dfr, ofr) {
	console.log('pull_associations_from_original_of_derivative')
	// for each non-isChildOf assocition from the original framework...
	for (let assoc of ofr.json.CFAssociations) {
		if (assoc.associationType == 'isChildOf') continue
		// copy the association, and add a flag that says we created the association this way
		new_assoc = $.extend(true, {}, assoc)
		new_assoc.from_original_of_derivative = true

		// if the origin or destination is an item that was copied from the original into the derivative...
		let copied = false
		let dest_framework_identifier

		// look for an item referenced by sourceItemIdentifier...
		if (dfr.cfo.sourceItemIdentifier_hash[new_assoc.originNodeURI.identifier]) {
			copied = true
			// sub the derivative identifier for the original identifier, and update the framework identifier in the title (but don't bother updating the URI)
			new_assoc.originNodeURI.identifier = dfr.cfo.sourceItemIdentifier_hash[new_assoc.originNodeURI.identifier]
			new_assoc.originNodeURI.title = new_assoc.originNodeURI.title.replace(ofr.lsdoc_identifier, dfr.lsdoc_identifier)

			// if the destinationNodeURI's title includes an identifier, it indicates the framework for the destination
			if (new_assoc.destinationNodeURI.title.search(/\(:(.*?)\:\)$/) > -1) dest_framework_identifier = RegExp.$1
		}
		if (dfr.cfo.sourceItemIdentifier_hash[new_assoc.destinationNodeURI.identifier]) {
			copied = true
			new_assoc.destinationNodeURI.identifier = dfr.cfo.sourceItemIdentifier_hash[new_assoc.destinationNodeURI.identifier]
			new_assoc.destinationNodeURI.title = new_assoc.destinationNodeURI.title.replace(ofr.lsdoc_identifier, dfr.lsdoc_identifier)

			if (new_assoc.originNodeURI.title.search(/\(:(.*?)\:\)$/) > -1) dest_framework_identifier = RegExp.$1
		}

		// or look for an alias recorded in between_framework_alias_hash
		if (dfr.cfo.between_framework_alias_hash[new_assoc.originNodeURI.identifier]) {
			copied = true
			if (new_assoc.originNodeURI.title.search(/\(:(.*?)\:\)$/) > -1) dest_framework_identifier = RegExp.$1
		}
		if (dfr.cfo.between_framework_alias_hash[new_assoc.destinationNodeURI.identifier]) {
			copied = true
			if (new_assoc.destinationNodeURI.title.search(/\(:(.*?)\:\)$/) > -1) dest_framework_identifier = RegExp.$1
		}

		if (copied) {
			// console.log('copying association')
			// if (new_assoc.originNodeURI.identifier == '0d6eff38-8ed2-4924-9971-12d1c4bbc217') console.log('found assoc', assoc, new_assoc)
			if (dest_framework_identifier) {
				// if we found a framework for the destination, copy the destination framework from the original to the derivative
				if (!dfr.cfo.associated_documents.find(x=>x.identifier == dest_framework_identifier)) {
					// in this case, we may have a framework-framework association in the original's json; if so copy it into this framework
					let ad = ofr.cfo.associated_documents.find(x=>x.identifier == dest_framework_identifier)
					if (ad) vapp.$store.commit('set', [dfr.cfo.associated_documents, 'PUSH', $.extend(true, {}, ad)])
					else {
						// the original's json *didn't* have a framework-framework association for this item association. See if we know about the to-be-copied association from framework_records
						let fr = vapp.$store.state.framework_records.find(x=>x.lsdoc_identifier == dest_framework_identifier)
						if (fr) {
							vapp.$store.commit('set', [dfr.cfo.associated_documents, 'PUSH', $.extend(true, {}, fr.json.CFDocument)])
						} else {
							// if we couldn't find the the assoc's framework, don't add the assoc, because we won't be able to display it
							console.log('pull_associations_from_original_of_derivative: couldn’t find assoc’s framework, so not adding assoc: ', $.extend(true, {}, assoc))
							continue
						}
					}
				}
			}

			// add to dfr.cfo.derivative_CFAssociations, which is used by update_frameworks_with_associations (called after this fn returns)
			dfr.cfo.derivative_CFAssociations.push(new_assoc)
		}
	}
}

U.find_cfassociation_index = function(cfassociations, child_identifier, parent_identifier, item_type) {
	if (!item_type) item_type = 'isChildOf'
	return cfassociations.findIndex(x=>
		x.associationType == item_type &&
		x.originNodeURI.identifier == child_identifier &&
		x.destinationNodeURI.identifier == parent_identifier
	)
}

U.find_all_is_child_of_associations = function(cfassociations, child_identifier, item_type) {
	if (!item_type) item_type = 'isChildOf'
	return cfassociations.filter(x=>
		x.associationType == item_type &&
		x.originNodeURI.identifier == child_identifier
	)
}

U.count_descendents = function(node) {
	let ct = node.children.length
	for (let child of node.children) {
		ct += U.count_descendents(child)
	}
	return ct
}

// determine if the given node is a descendent of the node with the given identifier
U.is_descendent_of = function(node, identifier) {
	while (node.parent_node && node.parent_node.cfitem) {
		if (node.parent_node.cfitem.identifier == identifier) return true
		node = node.parent_node
	}
}

U.get_next_sequenceNumber = function(parent_node, framework_record) {
	// determine the sequenceNumber to use for a new item being placed at the end of a parent node's children:
	// (the sequenceNumber of the parent's last child) + 1 -- or 1 if the parent has no children
	let child_count = parent_node.children.length
	if (child_count == 0) {
		return 1

	} else {
		let last_child_identifier = parent_node.children[child_count-1].cfitem.identifier
		let parent_identifier = parent_node.cfitem.identifier
		let assoc = framework_record.json.CFAssociations.find(x=>x.destinationNodeURI.identifier == parent_identifier && x.originNodeURI.identifier == last_child_identifier)
		// if we can't find a sequenceNumber for some reason, use child_count + 1
		if (empty(assoc) || empty(assoc.sequenceNumber)) return child_count + 1
		else return assoc.sequenceNumber*1 + 1
	}
}

// a `framework_record` is an object that includes the raw CASE json for the framework along with some additional non-CASE data about the framework
U.create_framework_record = function(lsdoc_identifier, case_json, ss_framework_data, framework_json_loaded) {
	// make sure case_json has the five top-level components (it must definitionally have a CFDocument already)
	if (empty(case_json.CFItems)) case_json.CFItems = []
	if (empty(case_json.CFAssociations)) case_json.CFAssociations = []
	if (empty(case_json.CFDefinitions)) case_json.CFDefinitions = {}
	if (empty(case_json.CFRubrics)) case_json.CFRubrics = []

	let framework_record = {
		lsdoc_identifier: lsdoc_identifier,		// if this is empty, it means it's a new framework (not yet saved)
		ss_framework_data: new SSFrameworkData(ss_framework_data, lsdoc_identifier, case_json.CFDocument),	// this can be empty coming in; if so default values will be set
		framework_json_loaded: (framework_json_loaded ? true: false),		// if this is null/false, it means we haven't actually tried to load the full framework json from the server
		framework_json_loading: false,
		json: case_json,
		cfo: null,
		fw_changes: null,	// this may be used to expose item changes in the tree view
		open_nodes: {},		// nodes (items with children) that are currently open (i.e. their children are viewable); this will be a hash of tree_keys
		pinned_items: [],	// items (identified by tree_keys) that are currently "pinned"
		selected_items: null,	// items (identified by identifiers) to be marked as "selected"; should be null if no items are selected
		selected_items_ancestor: '',	// if specified, indicates the ancestor (identifier) of selected items to open/limit to (see CASEFrameworkViewer)
		limit_to_selected_items: false,	// if 'only', *only* show the selected items (and their ancestors); if 'children', also show the selected items' children
		active_node: '',	// node (tree_key) whose tile is currently viewed in the active tile slot
		last_clicked_node: '',	// node last clicked by the user
		chosen_node: '',	// node (tree_key) selected via the 'show_chooser' interface
		featured_node: '',	// node (tree_key) that's currently “featured” for special processing; see e.g. MakeAssociationsAssistantMixin
		hovered_tile: '', 	// pinned tile that the user is currently hovering over
		checked_items: {},	// items checked; used for batch update and other things; this will be a hash of tree_keys
		clear_checkboxes_trigger: false,	// used to clear checkboxes in CASEItem, when not in viewer mode
		blob_item_table_key: null,	// in "blob mode", if this is set it indicates the node that we're showing a table for
		search_terms: '',
		document_identifier_showing_in_tree: lsdoc_identifier,
		sparkl_bot_vectors: null,		// NLP encodings of items in the framework; will be loaded, as a hash indexed by item identifiers, when necessary
	}
	return framework_record
}

U.framework_record_load_json = function(framework_record, case_json) {
	// make sure case_json has the five top-level components (it must definitionally have a CFDocument already)
	if (empty(case_json.CFItems)) case_json.CFItems = []
	if (empty(case_json.CFAssociations)) case_json.CFAssociations = []
	if (empty(case_json.CFDefinitions)) case_json.CFDefinitions = {}
	if (empty(case_json.CFRubrics)) case_json.CFRubrics = []

	vapp.$store.commit('set', [framework_record, 'json', case_json])
	vapp.$store.commit('set', [framework_record, 'framework_json_loaded', true])
}

U.cfitem_stats = {
	is_duplicate_of: null,
	possible_duplicate_ofs: null,
	possible_related_to: null,
	add_is_child_of_to_duplicate: null,
	to_be_deleted: null,
	duplicate_hovered: null,
	level: null,
	descendent_count: null,
	duplicate_count: 0,	// (only counted for document)
	items_removed: null,
	change: null,
}

U.create_cfo_cfitem = function(original_object) {
	return $.extend(true, original_object, {
		extensions: {},	// make sure the cfitem has an extensions object
		tree_nodes: [],
		stats: U.cfitem_stats
	})
}

U.create_cfo_node = function(cfo, new_cfitem, parent_node, sequence) {
	return {
		tree_key: cfo.next_tree_key,
		cfitem: new_cfitem,
		parent_node: parent_node,
		children: [],
		sequence: sequence,
	}
	// note: while node.sequence will usually be set to CFAssociation.sequenceNumber to start, this correspondence is not guaranteed to remain true
}

U.add_child_to_cfo = function(cfitem_data, parent_node, $store, cfo, sequence, children, recursing) {
	// see if the cfitem already exists
	let cfitem = cfo.cfitems[cfitem_data.identifier]

	// if it doesn't already exist, create a cfo cfitem (with extra data added)
	if (empty(cfitem)) {
		cfitem = U.create_cfo_cfitem(cfitem_data)

		// add the cfitem to the cfo's cfitems hash, then get a reference to the now-reactive cfitem
		$store.commit('set', [cfo.cfitems, cfitem.identifier, cfitem])
		cfitem = cfo.cfitems[cfitem.identifier]
	}

	// if sequence is not provided, add to the end of any children already in the parent_node
	if (sequence == null) {
		// setting sequence value to children.length will make it be spliced onto the end of the list, even if the list was formerly empty
		sequence = parent_node.children.length
	}

	// the new item's parent may exist in multiple places, so add the item's node to all such parent nodes, unless recursing is true, in which we only do the parent_node
	let parent_nodes
	if (recursing === true) parent_nodes = [parent_node]
	else parent_nodes = parent_node.cfitem.tree_nodes

	let returned_node
	for (let pn of parent_nodes) {
		// create a node
		let new_node = U.create_cfo_node(cfo, cfitem, parent_node, sequence)

		// add node to tree_nodes_hash
		$store.commit('set', [cfo.tree_nodes_hash, new_node.tree_key+'', new_node])

		// store tree_node in cfitem's tree_nodes array, then increment cfo.next_tree_key
		$store.commit('set', [cfitem.tree_nodes, 'PUSH', new_node])
		$store.commit('set', [cfo, 'next_tree_key', cfo.next_tree_key+1])

		// splice new_node into the parent's children (unless a sequence value was explicitly provided [which happens when an item is moved], we'll splice onto the end of the array)
		$store.commit('set', [pn.children, 'SPLICE', sequence, 0, new_node])

		// we want to return the child of the specified parent node, so stash it when we get to it
		if (pn == parent_node) returned_node = new_node

		// if the added node has children, we have to add them (and any grandchildren) as well.
		// Rather than searching for children here, we add children if they are provided as a param (the param should be an array of nodes)
		if (!empty(children) && children.length > 0) {
			for (let child_node of children) {
				// console.log('recurse for children!', children.length, child_node.cfitem.fullStatement)
				// send true as the last argument so that in the recursion, we only add to the returned_node
				U.add_child_to_cfo(child_node.cfitem, new_node, $store, cfo, child_node.sequence, child_node.children.concat([]), true)
				// console.log('back after recurse: children.length = ' + children.length)
			}
		}
	}

	// return the new_node
	return returned_node
}

// a `cfo` (“CASE Framework Object”) is a structure that starts with the CASE json and adds additional data used to show the framework as a tree structure.
// in the cfo, we have "cfitems"; these objects include some of the data from the original json's CFDocument and CFItems; but additional data is added in
// whenever we want to refer to the original json, we should use the capitalized versions of the names (e.g. "CFDocument")
U.build_cfo = function($worker, case_json, flag) {
	return new Promise((resolve, reject)=>{
		if (flag != 'no_loading') U.loading_start('Processing CASE data …')

		let ts = new Date().getTime()
		$worker.run((json, cfitem_stats) => {
			// note that the incoming json object will be copied when it comes into the worker
			let cfo = {}

			// prepare to create the cftree, by copying in the cfdocument with added fields
			let cfdocument = json.CFDocument

			// convert legacy satchel-specific item data into extensions.*
			if (!cfdocument.extensions) cfdocument.extensions = {}
			if (cfdocument.hasOwnProperty('sourceFrameworkIdentifier')) { cfdocument.extensions.sourceFrameworkIdentifier = cfdocument.sourceFrameworkIdentifier; delete cfdocument.sourceFrameworkIdentifier; }
			if (cfdocument.hasOwnProperty('sourceFrameworkURI')) { cfdocument.extensions.sourceFrameworkURI = cfdocument.sourceFrameworkURI; delete cfdocument.sourceFrameworkURI; }

			cfdocument.tree_nodes = []
			cfdocument.stats = cfitem_stats

			// put the CFItems into a hash, to make it easy to pull the data out [also keep an array of items in "top-to-bottom/left-to-right" order in the tree??]
			cfo.cfitems = {}
			// also create a hash FROM sourceItemIdentifiers (original items) TO identifiers (duplicates of originals, which may be from the same or a different framework); we use this in pull_associations_from_original_of_derivative
			cfo.sourceItemIdentifier_hash = {}
			// also tabulate a list of item types used in the framework; used, e.g., in the search interface
			cfo.item_types = []
			let item_type_hash = {}
			var associations_hash = {}
			var ai_only_associations_hash = {}
			for (let i = 0; i < json.CFItems.length; ++i) {
				let item = json.CFItems[i]

				// convert legacy satchel-specific item data into extensions.*
				if (!item.extensions) item.extensions = {}
				if (item.hasOwnProperty('supplementalNotes')) { item.extensions.supplementalNotes = item.supplementalNotes; delete item.supplementalNotes; }
				if (item.hasOwnProperty('sourceItemIdentifier')) { item.extensions.sourceItemIdentifier = item.sourceItemIdentifier; } // delete item.sourceItemIdentifier; }
				if (item.hasOwnProperty('sourceItemURI')) { item.extensions.sourceItemURI = item.sourceItemURI; } // delete item.sourceItemURI; }
				if (item.hasOwnProperty('isSupplementalItem')) { item.extensions.isSupplementalItem = item.isSupplementalItem; } // delete item.isSupplementalItem; }		

				// add tree_nodes array for use below; also add stats
				item.tree_nodes = []
				item.stats = cfitem_stats

				cfo.cfitems[item.identifier] = item

				// update item_types
				let item_type = item.CFItemType ? item.CFItemType : (item.CFItemTypeURI ? item.CFItemTypeURI.title : '')
				// normalize from "Standard" to "Content Standard" (??)
				// if (item_type == 'Standard') item_type = 'Content Standard'
				if (item_type && !item_type_hash[item_type]) {
					cfo.item_types.push(item_type)
					item_type_hash[item_type] = true
				}

				// if this item is a copy of another item identified by a sourceItemIdentifier, add to sourceItemIdentifier_hash
				if (item.extensions?.sourceItemIdentifier && item.extensions.sourceItemIdentifier != item.identifier) {
					cfo.sourceItemIdentifier_hash[item.extensions.sourceItemIdentifier] = item.identifier
				}
			}

			// sort the item_types
			cfo.item_types.sort()

			// also put the nodes in a hash
			cfo.tree_nodes_hash = {}

			cfo.next_tree_key = 1

			// create temporary hash of isChildOf associations to use when building the tree structure; for a huge framework like CTAE (31 MB), speeds up tree-building from ~30 seconds to .75 seconds
			var is_child_of_hash = {}
			// and find items that we know are aliases of items in other frameworks because they have sourceDocumentIdentifier extensions in the associations
			cfo.between_framework_alias_hash = {}
			// also find associated documents, and build hash of other associations for use when showing associations in the tree
			var associated_documents = []
			for (var i = 0; i < json.CFAssociations.length; ++i) {
				var a = json.CFAssociations[i]
				// isChildOf associations...
				if (a.associationType == 'isChildOf') {
					if (!is_child_of_hash[a.destinationNodeURI.identifier]) is_child_of_hash[a.destinationNodeURI.identifier] = []
					is_child_of_hash[a.destinationNodeURI.identifier].push({
						child_identifier: a.originNodeURI.identifier,
						sequence: a.sequenceNumber
					})

					// look for sourceDocumentIdentifier
					if (a.extensions?.sourceDocumentIdentifier) {
						// we record the sourceDocumentIdentifier in the hash
						cfo.between_framework_alias_hash[a.originNodeURI.identifier] = a.extensions.sourceDocumentIdentifier
					}

				// ext:satchelAIOnly associations -- these go in a special hash, and we currently allow for only one per item
				} else if (a.associationType == 'ext:satchelAIOnly') {
					ai_only_associations_hash[a.originNodeURI.identifier] = a

				// other associations...
				} else {
					// if framework A includes an association between an item in framework A and framework B, we include a document A -> isRelatedTo -> document B association,
					//     to mark the fact that framework A needs to look at framework B for the items in framework B
					//     if A includes an association between an item in B and an item in C, we include an A -> isRelatedTo -> B association and an A -> isRelatedTo -> C association
					// so if the originNodeURI.identifier is the document and the associationType is isRelatedTo, it marks an associated document
					if (a.originNodeURI.identifier == json.CFDocument.identifier && a.associationType == 'isRelatedTo') {
						if (!associated_documents.find(x=>x==a.destinationNodeURI.identifier)) {
							// we can derive the basics of the document CASE json -- the identifier, title, and URI -- from the destinationNodeURI
							associated_documents.push({
								identifier: a.destinationNodeURI.identifier,
								uri: a.destinationNodeURI.uri,
								title: a.destinationNodeURI.title,
							})
						}

					} else {
						// else put in associations_hash; here we want to be able to easily access this association via either the destination or origin identifier
						if (!associations_hash[a.destinationNodeURI.identifier]) associations_hash[a.destinationNodeURI.identifier] = []
						associations_hash[a.destinationNodeURI.identifier].push(a)

						if (!associations_hash[a.originNodeURI.identifier]) associations_hash[a.originNodeURI.identifier] = []
						associations_hash[a.originNodeURI.identifier].push(a)

						// see U.update_associations_hash for how we should be updating this when new associations are created
					}
				}
			}
			cfo.associated_documents = associated_documents
			cfo.associations_hash = associations_hash
			cfo.ai_only_associations_hash = ai_only_associations_hash
			cfo.alias_hash = {}
			cfo.derivative_CFAssociations = []
			cfo.displayed_associations_hash = {}	// this will be calculated by update_associations_to_show
			cfo.displayed_association_counts_hash = {}

			var item_type_levels = {}

			/////////////////// construct tree structure using recursive function
			var max_level = 0
			var build_node = function(cfitem, parent_node, sequence, level) {
				// build up a record of the tree “levels” for each item type, used to color items
				if (!level) level = 0
				if (level > max_level) max_level = level
				let item_type = cfitem.CFItemType ? cfitem.CFItemType : (cfitem.CFItemTypeURI ? cfitem.CFItemTypeURI.title : '')
				if (item_type) {
					if (!item_type_levels[item_type]) {
						item_type_levels[item_type] = {sum: 0, count: 0}
					}
					item_type_levels[item_type].sum += level
					item_type_levels[item_type].count += 1

					// For debugging purposes if needed
					// if (item_type == 'Cluster') {
					// 	console.log('Cluster: ' + level + ' ' + cfitem.fullStatement)
					// }
				}

				var node = {
					tree_key: cfo.next_tree_key,
					cfitem: cfitem,
					parent_node: parent_node,
					children: [],
					sequence: sequence,
					flashing: false,	// used to flash the node in the tree, e.g. when making an association
					comp_score: -1,		// this and the next three values are used for associations and alignments
					comp_score_tooltip: '',
					comp_score_highlighted: false,
					cat: '',	// current association type
				}

				// add new node to tree_nodes_hash
				cfo.tree_nodes_hash[node.tree_key] = node

				// if this is an alias to another already-created node, add the item's tree_nodes array to alias_hash; used in update_frameworks_with_associations
				if (cfitem.tree_nodes.length > 0) {
					cfo.alias_hash[cfitem.identifier] = cfitem.tree_nodes
				}

				// store tree_node in cfitem, then increment cfo.next_tree_key for the next one
				cfitem.tree_nodes.push(node)
				++cfo.next_tree_key

				// recursively process children
				if (is_child_of_hash[node.cfitem.identifier]) {
					for (var i = 0; i < is_child_of_hash[node.cfitem.identifier].length; ++i) {
						if (!cfo.cfitems[is_child_of_hash[node.cfitem.identifier][i].child_identifier]) {
							console.log('isChildOf identifier points to/from an item not in this framework: ' + is_child_of_hash[node.cfitem.identifier][i].child_identifier)
						} else {
							node.children.push(build_node(cfo.cfitems[is_child_of_hash[node.cfitem.identifier][i].child_identifier], node, is_child_of_hash[node.cfitem.identifier][i].sequence, level + 1))
						}
					}
				}

				// order children (primarily) by sequence. Note that the sequenceNumbers come from the *associations*, and that not all nodes have sequenceNumbers
				node.children.sort((a,b)=>{
					if (a.sequence && b.sequence) return a.sequence - b.sequence
					// note that sequence *should* always be an integer, and 0 evaluates as false; this is why we do 1 and -1 as shown below
					if (a.sequence && !b.sequence) return 1
					if (b.sequence && !a.sequence) return -1

					// this method of comparing might deal better with edge cases where no sequenceNumber is provided, but it takes extra processing cycles.
					// if (typeof(a.sequence) === 'number' && typeof(b.sequence) === 'number') return a.sequence - b.sequence
					// else if (typeof(a.sequence) === 'number') return 1
					// else if (typeof(b.sequence) === 'number') return -1

					// if neither child has a sequence, or they're both the same, sort on cfitem.humanCodingScheme, then on the title
					if (a.cfitem.humanCodingScheme < b.cfitem.humanCodingScheme) return -1
					if (b.cfitem.humanCodingScheme < a.cfitem.humanCodingScheme) return 1
					return 0
				})

				// Note: once we use the sequence values to sort above, we don't actually use them again for ordering purposes; 
				// the only other use for them is when adding brand new child or sibling items (from DocumentEditor/ItemEditor), where we stash the sequenceNumber value we want to use if/when the item is saved in node.sequence, then extract it in ItemEditor.save_changes

				return node
			}
			/////////////////// end of recursive fn
			cfo.cftree = build_node(cfdocument)
			// console.log('max_level: ' + max_level)

			// if framework has a set of levels explicitly specified, just set it... for now we explcitly code this for GA new ELA
			if (cfo.cftree.cfitem.identifier == '391c3abe-c1ec-4a4a-a942-c9e152b35102') {
				cfo.item_type_levels = [
					{item_type: 'Grade Band', level_index: 'deep-purple'},	//		??
					{item_type: 'Course', level_index: 'deep-orange'},	//			#F7E5D7 - peach
					{item_type: 'Domain', level_index: 'brown'},	//			??
					{item_type: 'Big Idea', level_index: 'green'},	//			#E4EFDB - green
					{item_type: 'Skill', level_index: 'green'},	//			#E4EFDB - green
					{item_type: 'Standard', level_index: 'blue'},	//			#E0EAF5 - blue
					{item_type: 'Expectation', level_index: 'yellow'},	//		#FCF3D0 - yellow
				]

			} else {
				// process item type level data
				cfo.item_type_levels = []
				for (var item_type in item_type_levels) {
					cfo.item_type_levels.push({
						item_type: item_type,
						sum: item_type_levels[item_type].sum,
						count: item_type_levels[item_type].count,
						avg_level_raw: item_type_levels[item_type].sum / item_type_levels[item_type].count,
					})
				}
				cfo.item_type_levels.sort((a,b) => {
					if (a.avg_level_raw != b.avg_level_raw) return a.avg_level_raw - b.avg_level_raw
					else return (a.item_type < b.item_type) ? -1 : 1
				})

				// we have 13 defined colors (0-12); use a different color for each one, cycling back through them if we need to
				// (this data is used in CASEItem, where a level-specific class is applied)
				var last_index = -1
				var last_rounded_level = -1
				for (var i = 0; i < cfo.item_type_levels.length; ++i) {
					cfo.item_type_levels[i].level_index = i % 13
				}
			}

			return cfo

		}, [case_json, U.cfitem_stats])	// this is where we pass the original case_json into the fn
		.then((cfo)=>{
			if (flag != 'no_loading') U.loading_stop()
			let time_elapsed = (new Date().getTime() - ts) / 1000
			console.log('time elapsed processing tree for ' + cfo.cftree.cfitem.identifier + ' (s): ' + time_elapsed)
			resolve(cfo)
		})
		.catch((e)=>{
			if (flag != 'no_loading') U.loading_stop()
			console.log('ERROR RUNNING BUILD_CFO', e)
			reject({})
		})
	})
}

U.process_cfo_stats = function($worker, original_cfo) {
	return new Promise((resolve, reject)=>{
		U.loading_start('Processing framework stats …')
		$worker.run((cfo) => {
			// first process nodes to count descendents and get *possible* duplicates
			var processed_items = []
			// duplicate_count is the number of duplicate items (only calculated for the document)
			var duplicate_count = 0

			function process_node_1(level, this_node) {
				var this_item = this_node.cfitem

				// for some reason this gets screwed up of we assign directly to this_item.stats
				var stats = Object.assign({}, this_item.stats)
				stats.level = level
				stats.possible_duplicate_ofs = []

				if (level != 0) {
					// for comparison purposes, make versions of fullStatement and hcs that are a) lowercase; b) space-normalized; c) trimmed; periods removed
					stats.fullStatement_lc = (this_item.fullStatement.toLowerCase().replace(/\.+/g, ' ').replace(/\s+/g, ' ')).replace(/^\s*(.*?)\s*$/, '$1')
					stats.humanCodingScheme_lc = (this_item.humanCodingScheme) ? (this_item.humanCodingScheme.toLowerCase().replace(/\.+/g, ' ').replace(/\s+/g, ' ')).replace(/^\s*(.*?)\s*$/, '$1') : ''

					for (var i = 0; i < processed_items.length; ++i) {
						var other_item = processed_items[i]

						// if hcs and fullstatement match...
						if (other_item.stats.humanCodingScheme_lc == stats.humanCodingScheme_lc && other_item.stats.fullStatement_lc == stats.fullStatement_lc) {
							// if the other_item is actually a duplicate of this item
							if (other_item.identifier == this_item.identifier) {
								// record the duplicate -- but only the first one
								if (!stats.is_duplicate_of) {
									stats.is_duplicate_of = other_item.identifier
								}
								// but always increment duplicate_count
								duplicate_count += 1

								// and for items that are already duplicates of other items, never suggest alternative duplicates
								stats.possible_duplicate_ofs = []
								break

							// else the other_item isn't already a duplicate of this item
							} else {
								// push to possible_duplicate_ofs
								stats.possible_duplicate_ofs.push(other_item.identifier)
							}
						}
					}
				}

				// note that descendent_count includes the parent plus its children
				stats.descendent_count = 1
				for (var j = 0; j < this_node.children.length; ++j) {
					var c = this_node.children[j]
					process_node_1(level+1, c)
					stats.descendent_count += c.cfitem.stats.descendent_count
				}

				this_node.cfitem.stats = stats
				// if (stats.possible_duplicate_ofs.length > 0) {
				// 	console.log('possible_duplicate_ofs: ' + this_node.cfitem.fullStatement.substr(0,15) + ' /' + stats.possible_duplicate_ofs.length)
				// }

				// push this after we process the children, because definitionally this item's children can't be duplicates of this item
				processed_items.push(this_node.cfitem)
			}
			process_node_1(0, cfo.cftree)

			// record duplicate_count for top-level cftree item (the document)
			cfo.cftree.cfitem.stats.duplicate_count = duplicate_count

			// define two more recursive functions
			function mark_item_and_children_as_deleted(this_node, pd_node) {
				// we need to know what item is being subbed for this item, so that we can re-map associations to the item if necessary
				this_node.cfitem.stats.to_be_deleted = pd_node.cfitem.identifier
				for (var z = 0; z < this_node.children.length; ++z) {
					mark_item_and_children_as_deleted(this_node.children[z], pd_node.children[z])
				}
			}

			function branches_are_duplicates(node1, node2) {
				// ??? assumes that node1 and node2 are themselves possible duplicates
				// if nodes have different numbers of children, they can't be duplicates
				if (node1.children.length != node2.children.length) {
					return false
				}

				// if item2 isn't a possible duplicate of item1, return false; the top-level branches don't match
				if (!node1.cfitem.stats.possible_duplicate_ofs.find(identifier=>identifier==node2.cfitem.identifier)) {
					return false
				}

				// go through each child (if there are any)
				for (var y = 0; y < node1.children.length; ++y) {
					if (!branches_are_duplicates(node1.children[y], node2.children[y])) {
						// if any pair of children aren't duplicates, the whole top-level branches don't match
						return false
					}
				}

				// if we get to here, the branch from here on down matches
				return true
			}

			// then re-process nodes...
			function process_node_2(this_node, possible_duplicate_of_parent) {
				var this_item = this_node.cfitem

				this_item.stats.items_removed = 0

				// if this item is a possible duplicate of at least one other item, check...
				if (this_item.stats.possible_duplicate_ofs.length > 0) {
					for (var i = 0; i < this_item.stats.possible_duplicate_ofs.length; ++i) {
						var possible_duplicate_identifier = this_item.stats.possible_duplicate_ofs[i]

						var pd_node = cfo.cfitems[possible_duplicate_identifier].tree_nodes[0]
						if (branches_are_duplicates(this_node, pd_node)) {
							// if branches_are_duplicates returns true, it's OK to remove this item and add an is_child_of to the duplicate
							this_item.stats.add_is_child_of_to_duplicate = possible_duplicate_identifier
							this_item.stats.items_removed = this_item.stats.descendent_count

							// and mark all children to be *deleted*, because the children will all implicitly be added to the tree structure along with this item
							for (var p = 0; p < this_node.children.length; ++p) {
								mark_item_and_children_as_deleted(this_node.children[p], pd_node.children[p])
							}

							// then return -- we don't have to further process this node's children (if there are any)
							return
						}
					}

					// if we get to here, the item can't be removed, but set possible_related_to to the first possible_duplicate_of
					this_item.stats.possible_related_to = this_item.stats.possible_duplicate_ofs[0]
				}

				// if we get to here, this item can't be duplicated, so recurse through the node's children
				for (var j = 0; j < this_node.children.length; ++j) {
					process_node_2(this_node.children[j])
					this_item.stats.items_removed += this_node.children[j].cfitem.stats.items_removed
				}
			}
			process_node_2(cfo.cftree)

			return cfo

		}, [original_cfo])	// this is where we pass the original cfo into the fn
		.then((cfo)=>{
			U.loading_stop()
			resolve(cfo)
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING process_cfo_stats', e)
			reject(original_cfo)
		})
	})
}

U.process_duplicates = function($worker, ojson, cfitems, case_current_time_string) {
	return new Promise((resolve, reject)=>{
		U.loading_start('Processing duplicate items …')
		$worker.run((ojson, cfitems, case_current_time_string) => {
			// initialize new json file; start with document and definitions, then add shell for items and associations
			var nf = {}
			nf.CFDocument = ojson.CFDocument
			nf.CFDefinitions = ojson.CFDefinitions
			nf.CFItems = []
			nf.CFAssociations = []

			// keep track of deleted_associations for final process below
			let deleted_associations = {}

			// DEBUG: keep track of items that are subbed for other items
			let subbed_identifiers = []

			// go through every item
			for (var j = 0; j < ojson.CFItems.length; ++j) {
				let item = ojson.CFItems[j]
				// get the cfitem stats from cfo_items
				let stats = cfitems[item.identifier].stats

				// if the item is marked as to_be_deleted or add_is_child_of_to_duplicate...
				if (stats.to_be_deleted || stats.add_is_child_of_to_duplicate) {
					let sub_identifier = (stats.to_be_deleted) ? stats.to_be_deleted : stats.add_is_child_of_to_duplicate
					subbed_identifiers.push(sub_identifier)

					// get the item we're going to sub in for this item, along with the originNodeURI from this item's isChildOf assoc, which we use below
					let sub_item = ojson.CFItems.find(o=>o.identifier == sub_identifier)
					let sub_item_isChildOf = ojson.CFAssociations.find(o => o.originNodeURI.identifier == sub_identifier && o.associationType == 'isChildOf')
					if (!sub_item_isChildOf) {
						let to_be_deleted = !stats.add_is_child_of_to_duplicate
						console.log('XXX (' + to_be_deleted + '): ' + sub_item.fullStatement, sub_item)
						continue
					}
					let sub_item_nodeURI = sub_item_isChildOf.originNodeURI

					// go through all associations *from* this item (associations where this item is the originNode)
					let assocs_from = ojson.CFAssociations.filter(o => o.originNodeURI.identifier == item.identifier)
					for (let i = 0; i < assocs_from.length; ++i) {
						let a = assocs_from[i]
						// if we already added the assoc, continue (I don't think this is necessary, but check just in case)
						if (nf.CFAssociations.find(o=>o.identifier==a.identifier)) {
							console.log('already added assoc FROM ' + a.identifier)
							continue
						}

						// don't copy isChildOfs for to_be_deleted items (but do copy other associations for these items)
						if (stats.to_be_deleted) {
							if (a.associationType == 'isChildOf') {
								// console.log('deleting assoc ' + a.identifier)
								deleted_associations[a.identifier] = true
								continue
							}
						}
						// isChildOfs for add_is_child_of_to_duplicate do get copied through, but the originNodeURI is changed to the subbed item

						// make a shallow copy of the association, so we leave ojson alone in case this item is subbing for something else
						let ca = Object.assign({}, a)

						// set originNodeURI to the value from sub_item_isChildOf
						if (ca.originNodeURI.identifier == item.identifier) {
							ca.originNodeURI = Object.assign({}, sub_item_nodeURI)
						}

						// update lastChangeDateTime; leave everything else (e.g. destinationNodeURI, identifier, sequenceNumber) alone; add to CFAssociations
						ca.lastChangeDateTime = case_current_time_string
						nf.CFAssociations.push(ca)
					}

					// now look at non-isChildOf associations *to* these items (associations where this item is the destinationNode)
					let assocs_to = ojson.CFAssociations.filter(o => o.destinationNodeURI.identifier == item.identifier)
					for (let i = 0; i < assocs_to.length; ++i) {
						let a = assocs_to[i]

						// *don't* add isChildOf associations to these items, because if the item is being removed, becuase isChildOf associations are always "internal" (they don't involve outside frameworks),
						// and we've already determined that the item's children can be "copied" into the tree structure as children of the sub_item
						if (a.associationType == 'isChildOf') {
							deleted_associations[a.identifier] = true
							continue
						}

						// if we already added the assoc, continue (I don't think this is necessary, but check just in case)
						if (nf.CFAssociations.find(o=>o.identifier==a.identifier)) {
							console.log(sr('already added assoc TO ' + a.identifier))
							continue
						}

						// make a shallow copy of the association, so we leave ojson alone in case this item is subbing for something else
						let ca = Object.assign({}, a)

						// set destinationNodeURI to the value from sub_item_isChildOf
						if (ca.destinationNodeURI.identifier == item.identifier) {
							ca.originNodeURI = Object.assign({}, sub_item_nodeURI)
						}

						// update lastChangeDateTime; leave everything else (e.g. destinationNodeURI, identifier, sequenceNumber) alone; add to CFAssociations
						ca.lastChangeDateTime = case_current_time_string
						nf.CFAssociations.push(ca)
					}

					// *don't* add these items
					continue
				}

				// for items that are passing through, add the item here; we'll add its associations below
				nf.CFItems.push(item)
			}

			// for all associations we didn't copy above and didn't mark to be deleted (i.e. associations for passed-through items and associations exclusively about outside items), add them to nf.CFAssociations here
			for (let i = 0; i < ojson.CFAssociations.length; ++i) {
				let a = ojson.CFAssociations[i]
				if (!nf.CFAssociations.find(o=>o.identifier == a.identifier) && !deleted_associations[a.identifier]) {
					nf.CFAssociations.push(a)
				}
			}

			return nf

		}, [ojson, cfitems, case_current_time_string])	// this is where we pass the original cfo into the fn
		.then((nf)=>{
			U.loading_stop()
			resolve(nf)
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING process_duplicates', e)
			reject()
		})
	})
}

U.reduce_case_json = function($worker, ojson, fns) {
	return new Promise((resolve, reject)=>{
		U.loading_start()
		console.log(fns)
		$worker.run((json, fns) => {
			// var original_json_length = window.JSON.stringify(json).length
			// var last_json_length = original_json_length

			// var report_text = 'REDUCE JSON: ' + json.CFDocument.title + ' (' + json.CFDocument.identifier + ')'
			// report_text += '\n'
			// report_text += original_json_length.toLocaleString() + ': ' + json.CFItems.length + ' items / ' + json.CFAssociations.length + ' associations'
			// report_text += '\n'
			//
			// function report(operation) {
			// 	return
			// 	var json_length = JSON.stringify(json).length
			//
			// 	var this_reduction = Math.round((last_json_length - json_length) / original_json_length * 10000) / 100
			// 	var cumulative_reduction = Math.round((1 - json_length / original_json_length) * 10000) / 100
			//
			// 	report_text += json_length.toLocaleString() + ' (this: ' + this_reduction + '% / cum: ' + cumulative_reduction + '%): ' + operation
			// 	report_text += '\n'
			//
			// 	last_json_length = json_length
			// }
			function report(operation) {
				console.log(operation)
			}

			var fn_defs = {}

			//////////////////////////////
			// STILL VALID CASE

			// NOTE: we don't run this anymore, because we now require ItemTypeURIs
			fn_defs.remove_ItemTypeURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					// if we have a CFItemTypeURI get rid of it...
					if (obj.CFItemTypeURI) {
						// I observed in some CTAE items that there was sometimes a discrepency between CFItemType and CFItemTypeURI; if we find such a discrepency, go with CFItemType
						// if we don't have a CFItemType, go with the CFItemTypeURI title
						if (!obj.CFItemType) {
							obj.CFItemType = obj.CFItemTypeURI.title
							delete obj.CFItemTypeURI
						} else {
							// else just delete the CFItemTypeURI
							delete obj.CFItemTypeURI
						}
					}
				}
				report('remove_ItemTypeURIs')
			}

			fn_defs.remove_ConceptKeywordsURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.conceptKeywordsURI) {
						// TODO: for now just delete this; work later on converting if needed
						delete obj.conceptKeywordsURI
					}
				}
				report('remove_ConceptKeywordsURIs')
			}

			fn_defs.shorten_node_titles = function(json, empty) {
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (empty === true) {
						obj.originNodeURI.title = ''
						obj.destinationNodeURI.title = ''
					} else if (obj.associationType == 'isChildOf') {
						// for isChildOf assocs, shorten to max 20 chars + …
						// (for other assocs, leave full text so we can show it without having to look up the item's framework)
						obj.originNodeURI.title = obj.originNodeURI.title.replace(/^(....................).+/, '$1…')
						obj.destinationNodeURI.title = obj.destinationNodeURI.title.replace(/^(....................).+/, '$1…')
					}
				}
				report('shorten_node_titles')
			}

			fn_defs.remove_empty_properties = function(json) {
				// remove non-required properties that are empty from CFAssociations and CFItems
				// note that this fn is not currently dealing with extensions
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]

					if (obj.humanCodingScheme && obj.humanCodingScheme === '') delete obj.humanCodingScheme
					if (obj.abbreviatedStatement && obj.abbreviatedStatement === '') delete obj.abbreviatedStatement
					if (obj.notes && obj.notes === '') delete obj.notes

					if (obj.language && obj.language === '') delete obj.language
					if (obj.alternativeLabel && obj.alternativeLabel === '') delete obj.alternativeLabel
					if (obj.listEnumeration && obj.listEnumeration === '') delete obj.listEnumeration
					if (obj.CFItemType && obj.CFItemType === '') delete obj.CFItemType
					if (obj.statusStartDate && obj.statusStartDate === '') delete obj.statusStartDate
					if (obj.statusEndDate && obj.statusEndDate === '') delete obj.statusEndDate

					if (obj.educationLevel && (obj.educationLevel === '' || obj.educationLevel.length == 0)) delete obj.educationLevel
					if (obj.conceptKeywords && (obj.conceptKeywords === '' || obj.conceptKeywords.length == 0)) delete obj.conceptKeywords
					if (obj.CFItemTypeURI && obj.CFItemTypeURI.identifier === '') delete obj.CFItemTypeURI
					if (obj.conceptKeywordsURI && obj.conceptKeywordsURI.identifier === '') delete obj.conceptKeywordsURI
					if (obj.licenseURI && obj.licenseURI.identifier === '') delete obj.licenseURI
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (obj.CFAssociationGroupingURI && obj.CFAssociationGroupingURI.identifier === '') {
						// TODO: for now we're just deleting empty CFAssociationGroupingURIs; work later on deciding if we can validly delete others
						delete obj.CFAssociationGroupingURI
					}
				}
				report('remove_empty_properties')
			}

			fn_defs.remove_CFDocumentURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.CFDocumentURI) delete obj.CFDocumentURI
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (obj.CFDocumentURI) delete obj.CFDocumentURI
				}
				report('remove_CFDocumentURIs')
			}

			// note that this should not be run before anything checking CFAssociations
			fn_defs.remove_orphan_items = function(json) {
				// go through all items; any item that (doesn't have an association where it is an origin) -- i.e. (isn't a child of something) -- should be deleted
				// 		also delete all associations where that item is the destination
				// keep doing this until we don't change anything; this removes orphan children, grandchildren, etc.
				var data = {
					CFItems: [],
					CFAssociations: [],
				}

				var ct = json.CFItems.length
				var last_ct, act
				do {
					last_ct = ct
					ct = 0
					// go through all items
					for (var i = json.CFItems.length - 1; i >= 0; --i) {
						var item = json.CFItems[i]

						if (item.delete) continue

						// delete all circular association references for this item
						let circular_assoc_index
						while ((circular_assoc_index = json.CFAssociations.findIndex(x=>x.originNodeURI?.identifier == item.identifier && x.destinationNodeURI?.identifier == item.identifier)) > -1) {
							let assoc = json.CFAssociations[circular_assoc_index]
							console.log('circular reference for item: ', item.identifier, assoc.identifier)
							json.CFAssociations[circular_assoc_index] = {identifier:assoc.identifier, delete:'yes'}
							data.CFAssociations.push(json.CFAssociations[circular_assoc_index])
						}

						// delete isChildOf assocs from this item to items that don't exist
						let cassocs = json.CFAssociations.filter(x=>x.originNodeURI?.identifier == item.identifier && x.associationType == 'isChildOf' && x.delete != 'yes')
						for (let j = 0; j < cassocs.length; ++j) {
							let assoc = cassocs[j]
							if (!(assoc.destinationNodeURI.identifier == json.CFDocument.identifier || json.CFItems.find(x=>x.identifier == assoc.destinationNodeURI.identifier))) {
								console.log('bad isChildOf assoc (parent doesn’t exist) for item: ', item, assoc)
								// console.log('bad isChildOf assoc (parent doesn’t exist) for item: ', item.identifier, assoc.identifier)
								let index = json.CFAssociations.findIndex(x=>x.identifier == assoc.identifier)
								json.CFAssociations[index] = {identifier:assoc.identifier, delete:'yes'}
								data.CFAssociations.push(json.CFAssociations[index])
							}
						}

						// now check to see if this item is not the origin of a (remaining) *isChildOf* association
						if (json.CFAssociations.findIndex(x=>x.originNodeURI?.identifier == item.identifier && x.associationType == 'isChildOf' && x.delete != 'yes') == -1) {
							// go through all associations
							for (var j = json.CFAssociations.length - 1; j >= 0; --j) {
								var assoc = json.CFAssociations[j]
								if (assoc.delete) continue
								// if this assoc's origin or destination is this item, delete the association
								if (assoc.destinationNodeURI.identifier == item.identifier || assoc.originNodeURI.identifier == item.identifier) {
									json.CFAssociations[j] = {identifier:assoc.identifier, delete:'yes'}
									data.CFAssociations.push(json.CFAssociations[j])
								}
							}

							// now mark item for deletion
							json.CFItems[i] = {identifier:item.identifier, delete:'yes'}
							data.CFItems.push(json.CFItems[i])

							var title = item.fullStatement
							if (item.humanCodingScheme) title = item.humanCodingScheme + ' ' + title
							console.log('DEL: ' + item.identifier + ' ' + title)

						} else {
							++ct
						}
					}
				} while (ct != last_ct)

				// if (json.CFItems.length == ct) report('remove_orphan_items: no items to be removed')
				if (data.CFItems.length == 0 && data.CFAssociations.length == 0) report('remove_orphan_items: no items to be removed')
				else report('remove_orphan_items: removing ' + (data.CFItems.length) + ' items and ' + (data.CFAssociations.length) + ' associations')

				return data
			}

			fn_defs.remove_zero_sequenceNumbers = function(json, empty) {
				let total_update_count = 0
				let zero_update_count = 0
				let empty_update_count = 0
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					// if/when we find an isChildOf assoc with sequenceNumber 0, or without sequenceNumbers
					if (obj.associationType == 'isChildOf' && (obj.sequenceNumber*1 === 0 || obj.sequenceNumber === undefined)) {
						if (obj.sequenceNumber === undefined) ++empty_update_count
						else ++zero_update_count

						// make a list of all its siblings
						let sibs = []
						for (var j = 0; j < json.CFAssociations.length; ++j) {
							if (json.CFAssociations[j].associationType == 'isChildOf' && json.CFAssociations[j].destinationNodeURI.identifier == obj.destinationNodeURI.identifier) {
								sibs.push(json.CFAssociations[j])
							}
						}

						// Sort sibs; note that assocs are not guaranteed to have sequenceNumbers
						sibs.sort((a,b)=>{
							if (a.sequenceNumber!=null && b.sequenceNumber!=null) return a.sequenceNumber - b.sequenceNumber
							if (a.sequenceNumber!=null && b.sequenceNumber==null) return -1
							if (b.sequenceNumber!=null && a.sequenceNumber==null) return 1
							return 0
						})

						// set new sequenceNumbers for all sibs, starting at 1
						for (var j = 0; j < sibs.length; ++j) {
							sibs[j].sequenceNumber = j + 1
							++total_update_count
						}
					}
				}
				report('remove_zero_sequenceNumbers: ' + zero_update_count + ' zeros and ' + empty_update_count + ' empties / ' + total_update_count + ' total changed')

				// return total number of fixed sequenceNumbers
				return {
					zero_update_count: zero_update_count,
					empty_update_count: empty_update_count,
					total_update_count: total_update_count,
				}
			}

			//////////////////////////////
			// recoverable from context
			fn_defs.remove_uris = function(json) {
				var doc_uri_stem = json.CFDocument.uri.replace(new RegExp(json.CFDocument.identifier), '')

				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.uri.replace(new RegExp(obj.identifier), '') == doc_uri_stem) delete obj.uri
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (obj.uri.replace(new RegExp(obj.identifier), '') == doc_uri_stem) delete obj.uri
				}
				report('remove_uris')
			}

			fn_defs.remove_licenseURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.licenseURI && obj.licenseURI == json.CFDocument.licenseURI) {
						obj.licenseURI = 'doc'
					}
				}
				report('remove_licenseURIs')
			}

			fn_defs.reduce_nodes = function(json) {
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (json.CFItems.find(x=>x.identifier == obj.originNodeURI.identifier) || json.CFDocument.identifier == obj.originNodeURI.identifier) {
						obj.originNodeURI = obj.originNodeURI.identifier
					}
					if (json.CFItems.find(x=>x.identifier == obj.destinationNodeURI.identifier) || json.CFDocument.identifier == obj.destinationNodeURI.identifier) {
						obj.destinationNodeURI = obj.destinationNodeURI.identifier
					}
				}
				report('reduce_nodes')
			}

			fn_defs.remove_timezones = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					obj.lastChangeDateTime = obj.lastChangeDateTime.replace(/\+00:00$/, '')
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					obj.lastChangeDateTime = obj.lastChangeDateTime.replace(/\+00:00$/, '')
				}
				report('remove_timezones')
			}

			// note that if you do this before other fns, it will screw the fns up
			fn_defs.reduce_key_lengths = function(json) {
				var item_map = {
					'identifier': 'i',
					'fullStatement': 's',
					'alternativeLabel': 'al',
					'CFItemType': 't',
					'uri': 'u',
					'humanCodingScheme': 'c',
					'listEnumeration': 'le',
					'abbreviatedStatement': 'as',
					'conceptKeywords': 'k',
					'conceptKeywordsURI': 'ku',
					'notes': 'n',
					'language': 'l',
					'educationLevel': 'e',
					'CFItemTypeURI': 'tu',
					'licenseURI': 'lu',
					'statusStartDate': 'ss',
					'statusEndDate': 'se',
					'lastChangeDateTime': 'd',
				}

				var assoc_map = {
					'identifier': 'i',
					'associationType': 't',
					'sequenceNumber': 's',
					'uri': 'u',
					'originNodeURI': 'r',
					'destinationNodeURI': 'e',
					'CFAssociationGroupingURI': 'g',
					'lastChangeDateTime': 'd',
				}

				var assoc_type_map = {
					'isChildOf': 'c',
					'exactMatchOf': 'm',
					'isRelatedTo': 'r',
					'replacedBy': 'b',
					'exemplar': 'e',
					'hasSkillLevel': 's',
					'isPartOf': 'x',
					'isPeerOf': 'y',
					'precedes': 'z',
					'isTranslationOf': 't',
				}

				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					for (var key in item_map) {
						if (obj[key] !== undefined) {
							obj[item_map[key]] = obj[key]
							delete obj[key]
						}
					}
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]

					// first map associationType, then map keys
					var new_type_key = assoc_type_map[obj.associationType]
					if (new_type_key) obj.associationType = new_type_key

					for (var key in assoc_map) {
						if (obj[key] !== undefined) {
							obj[assoc_map[key]] = obj[key]
							delete obj[key]
						}
					}
				}
				report('reduce_key_lengths')
			}

			// if fns is a string, run that single fn and return the return value (see, e.g., store.delete_framework_items)
			if (typeof(fns) == 'string') {
				return fn_defs[fns](json)
			}

			// do all provided fns, once each
			var fns_run = {}
			var fn_return_vals = {}
			for (var z = 0; z < fns.length; ++z) {
				var fn = fns[z]
				if (fns_run[fn]) continue
				// debugger
				fn_return_vals[fn] = fn_defs[fn](json)
				fns_run[fn] = true
			}

			json.fn_return_vals = fn_return_vals

			return json

		}, [ojson, fns])	// this is where we pass the original data into the fn
		.then((rv)=>{
			U.loading_stop()

			// if fns is a string, run that single fn and return the return value (see, e.g., store.delete_framework_items)
			if (typeof(fns) == 'string') {
				resolve(rv)
				return
			}

			// extract fn_return_vals from rv json prior to doing counts below
			var fn_return_vals = rv.fn_return_vals
			delete rv.fn_return_vals
			console.log('fn_return_vals', fn_return_vals)

			var original_json_length = JSON.stringify(ojson).length
			var final_json_length = JSON.stringify(rv).length
			var cumulative_reduction = Math.round((1 - final_json_length / original_json_length) * 10000) / 100

			console.log('-----------------')
			var report_text = 'REDUCE JSON: ' + ojson.CFDocument.title + ' (' + ojson.CFDocument.identifier + ')'
			report_text += '\n'
			report_text += original_json_length.toLocaleString() + ': ' + ojson.CFItems.length + ' items / ' + ojson.CFAssociations.length + ' associations'
			report_text += '\n'
			report_text += final_json_length.toLocaleString() + ': reduction of ' + cumulative_reduction + '%'
			report_text += '\n'
			console.log(report_text)

			// return json and fn_return_vals
			resolve({json: rv, fn_return_vals: fn_return_vals})
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING reduce_case_json', e)
			reject()
		})
	})
}

// Map CASE property names (f) onto user-friendly descriptors
U.field_display_string = function(f, framework_record) {
	// for supplementalNotes, use the exemplar_label, if framework_record is passed in and the framework has a label specified
	if (f == 'supplementalNotes') return oprop(framework_record, 'ss_framework_data', 'exemplar_label') || 'Supplemental Information'
	else if (f == 'fullStatement') return 'Full Statement'
	else if (f == 'abbreviatedStatement') return 'Abbreviated Statement'
	else if (f == 'humanCodingScheme') return 'Human Readable Code'
	else if (f == 'notes') return 'Notes'
	else if (f == 'CFItemType') return 'Item Type'
	else if (f == 'CFItemTypeURI') return 'Item Type URI'
	else if (f == 'educationLevel') return 'Education Level'
	else if (f == 'language') return 'Language'
	else if (f == 'uri') return 'URI'
	else if (f == 'lastChangeDateTime') return 'Last Change Date'
	else if (f == 'statusStartDate') return 'Implementation Start Date'
	else if (f == 'statusEndDate') return 'Retirement Date'
	else if (f == 'licenseURI') return 'License'
	else if (f == 'Subject') return 'Subjects'
	else if (f == 'subjectURI') return 'Subject URIs'

	else if (f == 'officialSourceURL') return 'Official Source URL'
	else if (f == 'frameworkType') return 'Framework Type'
	else if (f == 'title') return 'Title'
	else if (f == 'creator') return 'Creator'
	else if (f == 'publisher') return 'Publisher'
	else if (f == 'description') return 'Description'
	else if (f == 'version') return 'Version'
	else if (f == 'adoptionStatus') return 'Adoption Status'

	else if (f == 'associationType') return 'Assn. Type'
	else if (f == 'originNodeURI.title') return 'Assn. Origin'
	else if (f == 'destinationNodeURI.title') return 'Assn. Destination'
	else if (f == 'sequenceNumber') return 'Assn. Sequence Number'
	else return f
}

// this is the standard way we determine if a framework should be viewable by any user
U.framework_is_private = function(doc_json) {
	// doc_json can either be the CFDocument json, or a framework_record
	if (doc_json.json) doc_json = doc_json.json.CFDocument
	return (doc_json && doc_json.adoptionStatus && doc_json.adoptionStatus.search(/private/i) > -1)
}

U.get_originals_from_sourceItemIdentifiers = function(val, framework_record) {
	let arr = (typeof(val) == 'string') ? [val] : val

	let rv = []
	for (let identifier of arr) {
		// if sourceItemIdentifier_translation isn't true, don't translate
		if (!framework_record.sourceItemIdentifier_translation || framework_record.json.CFItems.find(x=>x.identifier == identifier)) {
			rv.push(identifier)
		} else {
			let item = framework_record.json.CFItems.find(x=>x.extensions?.sourceItemIdentifier == identifier)
			if (item) rv.push(item.identifier)
			// couldn't find an item; may be the document, or could be it wasn't found; either way just add the identitifier
			else rv.push(identifier)
		}
	}

	return (typeof(val) == 'string') ? rv[0] : rv
}

U.process_framework_record_sourceItemIdentifiers = function(framework_record) {
	if (framework_record.selected_items && framework_record.selected_items.length > 1) {
		vapp.$store.commit('set', [framework_record, 'selected_items', U.get_originals_from_sourceItemIdentifiers(framework_record.selected_items, framework_record)])
	}
	if (framework_record.selected_items_ancestor) {
		vapp.$store.commit('set', [framework_record, 'selected_items_ancestor', U.get_originals_from_sourceItemIdentifiers(framework_record.selected_items_ancestor, framework_record)])
	}
}

U.progression_table_available = function(framework_record, item) {
	// this feature has to be turned on in the document editor
	if (!framework_record.ss_framework_data.show_progression_tables) return false

	// if no children, obviously there's no progression
	if (item.children.length == 0) return false

	// if the item's children have > 3 different educationLevel options, offer to show the progression table
	let gvs = []
	for (let child of item.children) {
		if (!child.cfitem.educationLevel || child.cfitem.educationLevel.length == 0) continue
		// skip items that probably aren't items in progression
		if (child.cfitem.fullStatement.search(/School|Grade|Primary|Middle|High/) > -1) continue
		let s = child.cfitem.educationLevel.join(',')
		if (!gvs.includes(s)) gvs.push(s)
		if (gvs.length > 2) {
			console.log(gvs)
			return true
		}
	}
	return false
}

U.keep_item_types_in_sync = function(payload) {
	// whenever we execute a save_framework_data command, if we're saving CFItems this will be called to make sure the saved CFItems include CFItemTypeURI structures, and make sure CFDefinitions.CFItemTypes includes records for the item types
	// note: the caller must have checked to make sure payload includes CFItems
	let fr = vapp.$store.state.framework_records.find(x=>x.lsdoc_identifier == payload.lsdoc_identifier)
	if (!fr) { vapp.$alert('keep_item_types_in_sync error 1'); return; }	// shouldn't happen
	let CFItemTypes = JSON.parse(JSON.stringify(fr.json.CFDefinitions?.CFItemTypes))
	if (!CFItemTypes) CFItemTypes = []
	let CFItemTypes_changed = false		// this flags if we changed the CFDefinitions.CFItemTypes
	let item_type_changed = false		// this flags if we changed the item type in at least one CFItem
	let updated_items = {}

	for (let item of payload.CFItems) {
		// if we're deleting any items, set item_type_changed to true, but don't include the item in updated_items
		if (item.delete == 'yes') {
			item_type_changed = true
			continue
		}

		updated_items[item.identifier] = item

		// if the item doesn't have a CFItemType or CFItemTypeURI, continue
		if ((empty(item.CFItemType) || item.CFItemType=='*CLEAR*') && (empty(item.CFItemTypeURI) || item.CFItemTypeURI=='*CLEAR*')) {
			continue
		}
		// if the item has a CFItemType and CFItemTypeURI...
		if ((item.CFItemType && item.CFItemType!='*CLEAR*') && (item.CFItemTypeURI && item.CFItemTypeURI!='*CLEAR*')) {
			// if the item's CFItemTypeURI.title doesn't match its CFItemType
			if (item.CFItemTypeURI.title != item.CFItemType) {
				// mark CFItemTypeURI as being cleared, so that we fix it below
				item.CFItemTypeURI = '*CLEAR*'
				item_type_changed = true
			} else {
				// else continue
				continue
			}
		}

		// if we get to here, we need to set/fix things...

		// if CFItemType is empty but we *do* have a CFItemTypeURI...
		if ((empty(item.CFItemType) || item.CFItemType=='*CLEAR*') && (item.CFItemTypeURI && item.CFItemTypeURI!='*CLEAR*')) {
			// we need to clear CFItemTypeURI; we'll check below if we need to clear a CFDefinitions.CFItemType
			item.CFItemTypeURI = '*CLEAR*'
			item_type_changed = true
			continue
		}

		// the only remaining option is that CFItemType is not empty and we don't have a CFItemTypeURI
		if ((item.CFItemType && item.CFItemType!='*CLEAR*') && (empty(item.CFItemTypeURI) || item.CFItemTypeURI=='*CLEAR*')) {
			// if the CFItemType doesn't already exist in CFItems, create a new one
			let o = CFItemTypes.find(x=>x.title == item.CFItemType)
			if (!o) {
				o = new CFItemType({title: item.CFItemType, identifier: U.new_uuid()})
				o.generate_uri(fr.json.CFDocument)
				o.lastChangeDateTime = '*NOW*'
				CFItemTypes.push(o.to_json())
				CFItemTypes_changed = true
			}
			// add the CFItemTypeURI; the LinkURI constructor will include just the values in a LinkURI
			item.CFItemTypeURI = new LinkURI(o)
			item_type_changed = true
			continue
		}

		// we shouldn't get to here
		vapp.$alert('keep_item_types_in_sync error 2'); return;
	}

	// any time an item type changed for at least one CFItem, see if we need to purge any CFDefinition.CFItemTypes values
	if (item_type_changed) {
		// for each CFDefinitions.CFItemTypes
		for (let j = CFItemTypes.length - 1; j >= 0; --j) {
			let cit = CFItemTypes[j]
			let found = false
			// for each CFItem in json
			for (let cfitem of fr.json.CFItems) {
				// skip updated items; we'll check those below
				if (updated_items[cfitem.identifier]) continue

				// if we found an item with this CFItemType, break
				if (cfitem.CFItemTypeURI?.identifier == cit.identifier) {
					found = true
					break
				}
			}
			for (let identifier in updated_items) {
				let cfitem = updated_items[identifier]
				// note that if this is a new item, it'll be in updated_items but won't be in fr.json.CFItems
				if (cfitem.CFItemTypeURI?.identifier == cit.identifier) {
					found = true
					break
				}
			}

			// if no items found, purge the type
			if (!found) {
				CFItemTypes.splice(j, 1)
				CFItemTypes_changed = true
			}
		}
	}

	// if CFItemTypes changed, add it to/replace it in payload
	// note that this means we always send *all* CFItemTypes if *any* CFItemTypes have changed (this is coordinated with save_framework_data.php)
	if (CFItemTypes_changed) {
		if (!payload.CFDefinitions) payload.CFDefinitions = {}
		payload.CFDefinitions.CFItemTypes = (CFItemTypes.length == 0) ? '*CLEAR*' : CFItemTypes
	}

	console.log('keep_item_types_in_sync done:', extobj(payload))
}

// this returns a list of the "sme-created" CFAssociations -- not including special-purpose CFAssociations that Satchel uses for its purposes in crosswalking and alilgning
U.get_crosswalk_sme_associations = function(CFAssociations) {
	// for now we just return the CFAssociations; soon we will filter...
	return CFAssociations
}

U.get_crosswalk_framework_record = function(framework_identifier_1, framework_identifier_2) {
	return vapp.$store.state.framework_records.find(x=>{
		// crosswalk framework will have frameworkType `crosswalk` 
		if (x.json.CFDocument.frameworkType != 'crosswalk') return false
		// and extensions.crosswalkSourceFrameworkIdentifiers that match frameworks a and b (in either order)
		if (x.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.length == 0) return false
		if (!x.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.includes(framework_identifier_1)) return false
		if (!x.json.CFDocument.extensions.crosswalkSourceFrameworkIdentifiers.includes(framework_identifier_2)) return false
		// if we get to here, all criteria are met
		return true
	})
}

U.create_crosswalk_framework = function(framework_record_1, framework_record_2) {
	return new Promise((resolve, reject)=>{
		// this generally parallels the create_new_framework method in FrameworkList
		// generate a new CFDocument with an identifier, and a sample uri
		let cfd = new CFDocument()
		cfd.generate_identifier()
		cfd.generate_uri(vapp.$store.state.framework_domain)

		cfd.title = `Crosswalk: “${framework_record_1.json.CFDocument.title}” <-> “${framework_record_2.json.CFDocument.title}” [${framework_record_1.json.CFDocument.identifier} <-> ${framework_record_2.json.CFDocument.identifier}]`
		cfd.creator = vapp.user_info.first_name + ' ' + vapp.user_info.last_name

		cfd.frameworkType = 'crosswalk'
		cfd.extensions.crosswalkSourceFrameworkIdentifiers = [
			framework_record_1.json.CFDocument.identifier,
			framework_record_2.json.CFDocument.identifier
		]

		cfd.adoptionStatus = 'Private Draft'
		cfd.language = 'en'

		let crosswalk_framework_record = U.create_framework_record(cfd.identifier, {CFDocument:cfd.to_json()})

		// store framework_record in state.framework_records; if nothing ever gets saved for the crosswalk, the crosswalk document won't be saved
		vapp.$store.commit('set', [vapp.$store.state.framework_records, 'PUSH', crosswalk_framework_record])

		// immediately create the cfo, then resolve
		U.build_cfo(vapp.$worker, crosswalk_framework_record.json).then((cfo)=>{
			vapp.$store.commit('set', [crosswalk_framework_record, 'cfo', cfo])
			vapp.$store.commit('set', [crosswalk_framework_record, 'framework_json_loading', false])
			resolve()
		})
	})
}

U.get_crosswalk_framework_stats = function(crosswalk_framework_record, framework_record) {
	const fw_ai_associations = Object
		.values(crosswalk_framework_record.cfo.ai_only_associations_hash)
		.filter((a) =>
			a.destinationNodeURI.identifier !== framework_record.lsdoc_identifier
		)

	const assoc_simscores = fw_ai_associations
		.filter((a) => a.extensions?.simscores)
		.map((a) => JSON.parse(a.extensions.simscores))

	const avg_matches = U.avg(assoc_simscores.map((s) => s.length))
	const avg_max_match_score = U.avg(assoc_simscores.map((s) => s[0][1]))
	const avg_max_stmt_score = U.avg(assoc_simscores.map((s) => s[0][2]))

	const items = Object.values(framework_record.cfo.cfitems)
	const fw_items_count = items.length
	const item_type_counts = items
		.filter((item) => fw_ai_associations.some((a) => a.originNodeURI.identifier === item.identifier))
		.reduce((acc, item) => {
			acc[item.CFItemType] = (acc[item.CFItemType] || 0) + 1
			return acc
		}, {})

	const fw_items_with_associations_count = fw_ai_associations.length

	const sme_count = Object.keys(crosswalk_framework_record.cfo.associations_hash).reduce((sum, item_id) => {
		if (item_id in framework_record.cfo.cfitems) {
			sum += 1;
		}
		return sum;
	}, 0);

	return {
		fw_items_with_associations_count,
		fw_items_count,
		percent: Math.round(fw_items_with_associations_count / fw_items_count * 100),
		avg_matches,
		avg_max_match_score,
		avg_max_stmt_score,
		item_type_counts,
		sme_count,
	}
}
