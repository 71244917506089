<template><div class="k-case-item-copier mt-2">
	<div class="pt-2 mt-2 text-center" style="border-top:1px solid #999; color:black; font-size:18px;"><b>Copy Child Items</b></div>

	<div class="k-case-ie-line pt-2">
		<v-checkbox class="mt-0 pt-0" v-model="duplicate_children" hide-details><template v-slot:label><span style="font-size:14px">Duplicate descendents of selected items</span></template></v-checkbox>
	</div>

	<div v-if="!copy_from_different_framework" class="k-case-ie-line">
		<v-checkbox class="mt-0 pt-0" v-model="use_sourceItemIdentifier" hide-details><template v-slot:label><span style="font-size:14px">Mark new items as copies of original items with sourceItemIdentifier tags</span></template></v-checkbox>
	</div>

	<div v-if="copy_from_different_framework" class="k-case-ie-line">
		<v-checkbox class="mt-0 pt-0" v-model="make_aliases" hide-details><template v-slot:label><span style="font-size:14px">Create “aliases” rather than full copies of original items</span></template></v-checkbox>
	</div>

	<div class="k-case-ie-line mb-1">
		<v-checkbox class="mt-0 pt-0" v-model="add_associations" hide-details><template v-slot:label><span style="font-size:14px">Add associations between copied items and original items</span></template></v-checkbox>
	</div>

	<div v-if="add_associations" class="k-case-ie-line">
		<div class="k-case-ie-line-label mr-3 ml-8"><nobr>Association type:</nobr></div>
		<div><v-radio-group v-model="association_type" hide-details row class="mt-0 ml-2">
			<v-radio background-color="#fff" class="mb-1" value="exactMatchOf"><template v-slot:label><span style="font-size:14px">Exact Match</span></template></v-radio>
			<v-radio background-color="#fff" class="mb-1" value="isRelatedTo"><template v-slot:label><span style="font-size:14px">Related To</span></template></v-radio>
		</v-radio-group></div>
	</div>				

	<div class="k-case-ie-line mt-3">
		<div class="k-case-ie-line-label mr-3 text-right" style="width:80px"><nobr>Copy from:</nobr></div>
		<v-autocomplete v-model="copy_from_identifier" :items="frameworks" label="" outlined background-color="#fff" dense hide-details></v-autocomplete>
	</div>

	<div class="mt-1 pt-2 text-center" style="border-top:1px solid #999">Choose item(s) to copy below, then click COPY SELECTED ITEMS to continue.</div>
	<div class="text-center mt-2 mb-3">
		<v-btn small color="secondary" class="mr-2" @click="$emit('cancel_copy')">Done</v-btn>
		<v-btn small color="primary" @click="copy_continue">Copy selected items…</v-btn>
	</div>

	<div v-if="copy_from_framework_record" class="k-case-item-copier-tree-holder">
		<CASETree :open_nodes_override="open_nodes_right" :show_checkbox_fn="checkbox_clicked" :framework_record="copy_from_framework_record" />
	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASETree from '../CASEFrameworkViewer/CASETree'

export default {
	components: { CASETree },
	props: {
		node_being_edited: { type: Object, required: true },
		framework_record: { required: true },
	},
	data() { return {
		copy_from_identifier: '',
		copy_from_framework_record: null,
		items_to_copy: [],
		open_nodes_right: {},
	}},
	computed: {
		...mapState(['framework_records']),
		...mapGetters([]),
		// allow for sourceFrameworkIdentifier to be in extensions or directly in the document
		// for params, store in state but not lst
		duplicate_children: {
			get() { return this.$store.state.item_copy_duplicate_children },
			set(val) { this.$store.commit('set', ['item_copy_duplicate_children', val]) },
		},
		use_sourceItemIdentifier: {
			get() { return this.$store.state.item_copy_use_sourceItemIdentifier },
			set(val) { this.$store.commit('set', ['item_copy_use_sourceItemIdentifier', val]) },
		},
		make_aliases: {
			get() { return this.$store.state.item_copy_make_aliases },
			set(val) { this.$store.commit('set', ['item_copy_make_aliases', val]) },
		},
		add_associations: {
			get() { return this.$store.state.item_copy_add_associations },
			set(val) { this.$store.commit('set', ['item_copy_add_associations', val]) },
		},
		association_type: {
			get() { return this.$store.state.item_copy_association_type },
			set(val) { this.$store.commit('set', ['item_copy_association_type', val]) },
		},
		derivative_framework_identifier() { return this.framework_record.json.CFDocument.extensions ? this.framework_record.json.CFDocument.extensions.sourceFrameworkIdentifier : this.framework_record.json.CFDocument.sourceFrameworkIdentifier },
		frameworks() {
			if (empty(this.framework_record)) return []

			let arr = [{value: this.framework_record.lsdoc_identifier, text: 'THIS FRAMEWORK: ' + this.framework_record.json.CFDocument.title}]
			// if this is a derivative framework, the original should always be the second framework listed
			if (this.derivative_framework_identifier) {
				let dfr = this.framework_records.find(x=>x.lsdoc_identifier == this.derivative_framework_identifier)
				if (dfr) {
					arr.push({value: this.derivative_framework_identifier, text: 'ORIGINAL FRAMEWORK: ' + dfr.json.CFDocument.title})
				} else {
					console.log('couldn’t find original of derivative framework')
				}
			}
			for (let fr of this.$store.getters.filtered_framework_records) {
				// skip the original framework...
				if (fr.lsdoc_identifier == this.derivative_framework_identifier) continue

				let doc = fr.json.CFDocument
				if (doc.identifier == this.framework_record.lsdoc_identifier) continue

				let text = U.framework_title_with_category(fr)
				// include, but mark, sandboxes
				if (!empty(fr.ss_framework_data.sandboxOfIdentifier)) text += ' [SANDBOX]'

				arr.push({value: doc.identifier, text: text})
			}
			// sort by title
			arr.sort((a,b)=>U.natural_sort(a.text, b.text))
			return arr
		},
		copy_from_different_framework() {
			return (this.copy_from_identifier !== this.framework_record.lsdoc_identifier)
		},
	},
	watch: {
		copy_from_identifier() {
			// reset items_to_copy if/when a new framework is specified
			this.items_to_copy = []

			// also reset open_nodes_right
			this.open_nodes_right = {}

			let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.copy_from_identifier)

			// if the framework hasn't yet been loaded, do so now
			if (!fr.framework_json_loaded) {
				this.load_framework()
			} else {
				// else set copy_from_framework_record here
				this.copy_from_framework_record = fr
			}
		},
	},
	created() {
	},
	mounted() {
		this.copy_from_identifier = this.framework_record.lsdoc_identifier
	},
	methods: {
		load_framework() {
			// first load the framework from the server
			U.loading_start('Loading framework…')
			this.$store.dispatch('get_lsdoc', this.copy_from_identifier).then(()=>{
				U.loading_stop()

				// then build the cfo for the framework
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.copy_from_identifier)
				U.build_cfo(this.$worker, fr.json).then((cfo)=>{
					this.$store.commit('set', [fr, 'cfo', cfo])

					// then set copy_from_framework_record
					this.copy_from_framework_record = fr

					U.loading_stop()
				})
				.catch((e)=>{
					U.loading_stop()
					console.log(e)
				})

			}).catch((e)=>{
				console.log(e)
				U.loading_stop('refresh_lsdoc')
				this.$alert('An error occurred when loading the competency framework.').then(x=>this.hide_tree())
			})
		},

		checkbox_clicked(identifier_clicked, tree_node_clicked, val) {
			let index = this.items_to_copy.findIndex(x=>x==tree_node_clicked)
			if (index > -1) this.items_to_copy.splice(index, 1)

			if (val) {
				this.items_to_copy.push(tree_node_clicked)
			}
		},

		copy_continue() {
			if (this.items_to_copy.length == 0) {
				this.$alert('You didn’t select any items to copy!')
				return
			}

			// prepare some data to show the user about exactly how many things will be copied
			let copied_descendent_count = 0

			for (let tree_key of this.items_to_copy) {
				// get the node to copy
				let node = this.copy_from_framework_record.cfo.tree_nodes_hash[tree_key]

				// for aliases (which definitionally come from this framework)...
				if (this.copy_type == 'alias') {
					copied_descendent_count += U.count_descendents(node)

					// make sure the aliased item is not the new parent (node_being_edited), checking by way of identifier, since the new parent could exist in multiple places in the tree
					if (node.cfitem.identifier == this.node_being_edited.cfitem.identifier) {
						this.$alert('You cannot alias an item as a child of itself (this would lead to an infinite loop!).')
						return
					}

					// make sure the aliased item is not an ancestor of the new parent (node_being_edited), remembering that the new parent could exist in multiple places in the tree
					for (let node_to_check of this.node_being_edited.cfitem.tree_nodes) {
						let node_to_check_parent = node_to_check.parent_node
						while (!empty(node_to_check_parent)) {
							// if (node_to_check_parent.cfitem.tree_nodes.findIndex(x=>x==node) > -1) {
							if (node_to_check_parent.cfitem.identifier == node.cfitem.identifier) {
								this.$alert(sr('At least one of the items you selected (“$1”) is an ancestor of the current item. You cannot create an alias in this situation (this would lead to an infinite loop!).', node.cfitem.fullStatement))
								return
							}
							node_to_check_parent = node_to_check_parent.parent_node
						}
					}

					// make sure this item doesn't already exist in the new parent
					if (this.node_being_edited.children.findIndex(x=>x.cfitem.identifier == node.cfitem.identifier) > -1) {
						this.$alert(sr('At least one of the items you selected (“$1”) is already a child of the current item. You cannot create an alias in this situation.', node.cfitem.fullStatement))
						return
					}

				// else for duplicates
				} else {
					if (this.duplicate_children) copied_descendent_count += U.count_descendents(node)
				}
			}

			// compose message to verify the operation with the user
			let item_msg = U.ps('one selected item', this.items_to_copy.length, this.items_to_copy.length + ' selected items')
			let descendent_msg = copied_descendent_count ? sr(', along with $1,', U.ps('one descendent item', copied_descendent_count, copied_descendent_count + ' descendent items')) : ''
			let msg = `This operation will copy ${item_msg}${descendent_msg} under the current item. Do you wish to proceed?`

			this.$confirm({
			    title: 'Please Confirm',
			    text: msg,
			    acceptText: 'Proceed',
			}).then(y => {
				this.finish_copy()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		finish_copy() {
			// prepare data to send to server, and add to the cfo
			let data = {
				update_item_types: true,	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes
				lsdoc_identifier: this.framework_record.lsdoc_identifier,
				CFItems: [],
				CFAssociations: [],
			}

			//////////////// recursively duplicate node and children
			// - node, copy_to_parent, store, framework_record: data to update tree and CFData
			let duplicate_item = (node, copy_to_parent, store, framework_record, first_iteration_node) => {
				// copy data from original item
				let new_CFItem_data = new window.CFItem(node.cfitem)
				
				// when duplicating, set new identifier, update lastChangeDateTime & URI
				// make_aliases

				let copy_type
				let use_sourceItemIdentifier
				// if copying from a different framework and make_aliases is true, we can make an alias as long as we don't already have an alias of the item in the current framework
				if (this.copy_from_different_framework) {
					if (this.make_aliases) {
						if (empty(this.framework_record.cfo.between_framework_alias_hash[new_CFItem_data.identifier])) {
							copy_type = 'alias'
							use_sourceItemIdentifier = false
						} else {
							// if we do already have an alias of the item, make a sourceItemIdentifier copy
							copy_type = 'duplicate'
							use_sourceItemIdentifier = true
						}
					}
				} else {
					// if copying from the same framework, make a duplicate, and use_sourceItemIdentifier is based on user setting
					copy_type = 'duplicate'
					use_sourceItemIdentifier = this.use_sourceItemIdentifier
				}

				let o
				// if we're making an alias, we don't change anything about the original item. if making a duplicate...
				if (copy_type == 'duplicate') {
					// if we're using sourceItemIdentifier...
					if (use_sourceItemIdentifier) {
						// If original item I1 doesn’t have a sourceItemIdentifier, set I1.sourceItemIdentifier/URI = I1.identifier/URI (and save it)
						let sourceItemIdentifier = node.cfitem.extensions.sourceItemIdentifier
						let sourceItemURI = node.cfitem.extensions.sourceItemURI
						if (!sourceItemIdentifier) {
							let new_original_CFItem_data = new window.CFItem(node.cfitem)
							sourceItemIdentifier = new_original_CFItem_data.extensions.sourceItemIdentifier = node.cfitem.identifier
							sourceItemURI = new_original_CFItem_data.extensions.sourceItemURI = node.cfitem.uri
							new_original_CFItem_data.generate_date

							// add updated original to data.CFItems
							if (this.framework_record == this.copy_from_framework_record) {
								data.CFItems.push(new_original_CFItem_data.to_json())
							}
						}

						// set sourceItemIdentifier/URI for copied item I2
						new_CFItem_data.extensions.sourceItemIdentifier = sourceItemIdentifier
						new_CFItem_data.extensions.sourceItemURI = sourceItemURI

					} else {
						// if user *isn't* using sourceItemIdentifier, make sure we *don't* set the sourceItemIdentifier for the copied item
						delete new_CFItem_data.extensions.sourceItemIdentifier
						delete new_CFItem_data.extensions.sourceItemURI
					}

					// set new identifier and update lastChangeDateTime & URI
					new_CFItem_data.identifier = U.new_uuid()
					new_CFItem_data.uri = U.generate_child_uri(this.framework_record.json.CFDocument, new_CFItem_data.identifier, 'CFItems')
					new_CFItem_data.generate_date()

					// remove CFItemTypeURI, but make sure we have a CFItemType string. This does two things: a) forces save_framework_data to add the CFItemType to the definitions if necessary; and b) makes it so that we ensure that all the CFItemType definitions in this framework are specific to this framework.
					o = new_CFItem_data.to_json()
					o.CFItemType = U.item_type_string(o)
					if (o.CFItemTypeURI) delete o.CFItemTypeURI

				} else {
					// alias copy: use the original item exactly
					o = new_CFItem_data.to_json()
				}

				// add duplicated or aliased item to data.CFItems
				data.CFItems.push(o)

				// get the sequenceNumber to use for the new node -- have to do this before we create the new node for get_next_sequenceNumber to work properly
				let sequenceNumber = U.get_next_sequenceNumber(copy_to_parent, framework_record)

				// create new node and add to cfo
				let new_node = U.add_child_to_cfo(new_CFItem_data, copy_to_parent, store, framework_record.cfo, null, [], true)

				let child_association = U.create_association(copy_to_parent.cfitem, new_CFItem_data, sequenceNumber, framework_record.json.CFDocument)

				// if this is an alias, set sourceDocumentIdentifier/uri on assoc
				if (copy_type == 'alias') {
					if (empty(child_association.extensions)) child_association.extensions = {}
					let source_document = this.copy_from_framework_record.json.CFDocument
					child_association.extensions.sourceDocumentIdentifier = source_document.identifier
					child_association.extensions.sourceDocumentURI = source_document.uri
				}

				// add the child_association association to data
				data.CFAssociations.push(child_association.to_json())

				// if add_associations is set, add an isRelatedTo or exactMatchOf association
				if (this.add_associations) {
					let assoc = new CFAssociation({
						associationType: this.association_type,
						originNodeURI: {
							title: U.generate_cfassociation_node_uri_title(new_node.cfitem, true) + sr(' (:$1:)', this.framework_record.lsdoc_identifier),
							identifier: new_node.cfitem.identifier,
							uri: new_node.cfitem.uri,
						},
						destinationNodeURI: {
							title: U.generate_cfassociation_node_uri_title(node.cfitem, true) + sr(' (:$1:)', this.copy_from_framework_record.lsdoc_identifier),
							identifier: node.cfitem.identifier,
							uri: node.cfitem.uri,
						}
					})

					// fill in other parts of the CFAssociation object, then push to data.CFAssociations
					assoc.complete_data(this.framework_record.json.CFDocument)	// this will add '*NOW*' as the lastChangeDateTime
					data.CFAssociations.push(assoc.to_json())
				}

				// if duplicate descendents option is selected recursively process children
				if (this.duplicate_children === true) {
					for (var i = 0; i < node.children.length; ++i) {
						// new_node is the parent of the new children
						duplicate_item(node.children[i], new_node, store, framework_record)
					}
				}
			}

			for (let tree_key of this.items_to_copy) {
				// get the node to copy
				let node = this.copy_from_framework_record.cfo.tree_nodes_hash[tree_key]

				// duplicate each selected node (and descendents if specified)
				duplicate_item(node, this.node_being_edited, this.$store, this.framework_record)
			}

			// Note that this is very similar to the end of the process in ItemImportInterface.vue, and also similar to ItemEditor.save_changes
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// add CFItems and CFAssociations to json
				for (let cfi of data.CFItems) {
					// note that we may have saved changes to original items from the current framework, so we need to update those too
					
					// create or update the CFItem in the framework json
					// use server date/time for all timestamps
					cfi.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					let index = this.framework_record.json.CFItems.findIndex(x=>x.identifier == cfi.identifier)
					if (index == -1) {
						this.$store.commit('set', [this.framework_record.json.CFItems, 'PUSH', cfi])

						// also update lastChangeDateTime in cfo
						let node = this.framework_record.cfo.cfitems[cfi.identifier]
						this.$store.commit('set', [node, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])

					} else {
						// if we're here, this was an original item for which we edited the sourceItemIdentifier/URI (and lastChangeDateTime)
						this.$store.commit('set', [this.framework_record.json.CFItems, 'SPLICE', index, cfi])

						// we have to update these vals in the cfo as well (for added items, we had to add them to the cfo before saving, to know the right sequenceNumber to use)
						let node = this.framework_record.cfo.cfitems[cfi.identifier]
						this.$store.commit('set', [node.extensions, 'sourceItemIdentifier', cfi.extensions.sourceItemIdentifier])
						this.$store.commit('set', [node.extensions, 'sourceItemURI', cfi.extensions.sourceItemURI])
						this.$store.commit('set', [node, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
					}
				}

				for (let cfa of data.CFAssociations) {
					// update lastChangeDateTime json for CFAssociation
					if (cfa.lastChangeDateTime == '*NOW*') {
						cfa.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}

					this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', cfa])

					// for related/exact match, have to also add to associations_hash for the cfo, and add to the tree
					if (cfa.associationType != 'isChildOf') {
						U.update_associations_hash(this.framework_record.cfo, cfa)
					}

					// for an alias, set between_framework_alias_hash
					if (cfa.extensions?.sourceDocumentIdentifier) {
						console.warn(`setting between_framework_alias_hash for ${cfa.originNodeURI.identifier} to ${cfa.extensions.sourceDocumentIdentifier}`)
						this.framework_record.cfo.between_framework_alias_hash[cfa.originNodeURI.identifier] = cfa.extensions.sourceDocumentIdentifier
					}
				}

				// once everything is taken care of here, re-run update_frameworks_with_associations, in case any assocs or copies need to be updated
				vapp.case_tree_component.update_frameworks_with_associations()

				// close the import dialog when we're done
				this.$emit('cancel_import')

			}).catch((e)=>{
				console.log(e)
				// in case of failure...
				this.$alert('Error saving copied items')
				// remove cfitems from the cfo
				for (let cfi of data.CFItems) {
					// find the item's node in the cfo tree and delete it
					let tree_node = this.framework_record.cfo.cfitems[cfi.identifier].tree_nodes[0]
					let index = tree_node.parent_node.children.findIndex(x=>x==tree_node)
					if (index == -1) {
						console.log('error deleting child node from parent')
					} else {
						this.$store.commit('set', [tree_node.parent_node.children, 'SPLICE', index])
					}

					// then delete it from cfitems
					this.$store.commit('set', [this.framework_record.cfo.cfitems, cfi.identifier, '*DELETE_FROM_STORE*'])
				}
			})
		},
	}
}
</script>

<style lang="scss">
.k-case-item-copier {
}

.k-case-item-copier-tree-holder {
	.k-case-tree-scroll-wrapper {
		padding:0;
		width: 100%;
		margin:0;
		.k-case-tree {
			margin-bottom:0;
			padding:0;
			box-shadow: none!important;
		}
	}

	.k-case-tree-item-document {
		// hide checkbox for document-level item
		.k-case-tree-item-btns {
			display:none!important;
		}
	}
}
</style>
